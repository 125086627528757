import _ from 'lodash';
import { i18Next } from '../../i18n';

export const Faq = {
  getTitle: {
    read(_root: any, { readField }) {
      const data = readField('data');

      return _.result(data, `${i18Next.language}.title`, data?.en?.title);
    },
  },
  getText: {
    read(_root: any, { readField }) {
      const data = readField('data');

      return _.result(data, `${i18Next.language}.text`, data?.en?.text);
    },
  },
};
