import { useQuery, gql } from '@apollo/client';
import { Config as ConfigType } from '~types';
import _ from 'lodash';

export const GET_CONFIG = gql`
  query getConfig {
    getConfig {
      trustpilot
      hostname
      isEU
      isRU
      minDepositSum
      wheelSpinCost
      getCountries {
        id
        key
        name
        image
        amountExclude
      }
      getCurrency {
        id
        char
        symbol
      }
      getPaymentSystems {
        id
        key
        name
        image
        countries
        aggregator
        desc
        type
        minAmount
        getPaymentCards {
          id
          nominal
          image
          url
          getCurrency {
            id
            symbol
          }
        }
      }
      seo
      getSaleTime
      discount
      isAnonDiscount
      userAgent
      ip
      getEvent {
        id
        props
        endDate
        getUrl
        isLaunched
        isEndend
        startDate
      }
      getBattlePass {
        id
        props
        endDate
        getUrl
        isLaunched
        isEndend
        startDate
        isStart
      }
      stream
    }
  }
`;

interface Data {
  getConfig: ConfigType;
}

export const useConfig = (): ConfigType => {
  const { data } = useQuery<Data>(GET_CONFIG);

  return data?.getConfig;
};
