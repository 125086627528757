import { useQuery, gql, DocumentNode } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Case as CaseType } from '~types';
import _ from 'lodash';

export const FRAGMENT_CASE_FILEDS = gql`
  fragment CaseFields on Case {
    id
    freeCaseV2minDeposit
    freeCaseV2countFreeOpen
    isDisable
    isAccess
    coins
    needToOpenForFree
    userOpenForFree
    userFreeOpen
    battlePassCoins
    category
    getBackgroundImage
    getImage
    resizedImages {
      middle_768 {
        images
        background
      }
      small_480 {
        images
        background
      }
      small_360 {
        images
        background
      }
    }
    getUrl
    seo
    type
    getName
    getDateEnd
    price
    getLeft
    getPromoCodes {
      id
      items
    }
    getLimit
    percent
    priceOld
    isDiscount
    isHit
    isNew
    color
    getActiveGame {
      id
      price
      createdAt
      getItem {
        id
        getName
        getImage
        color
        isStatTrak
      }
    }
    countOpen
  }
`;

interface Types {
  query: DocumentNode;
  fragments: {
    getCase: DocumentNode;
  };
}

interface Data {
  getCase: CaseType;
}

interface Variables {
  input: {
    id?: number;
    url?: string;
  };
}

export const GET_CASE: Types = {
  query: gql`
    query useGetCase($input: CaseInput!) {
      getCase(input: $input) {
        ...CaseFields
        getItems {
          id
          price
          getImage
          getName
          isStatTrak
          color
          name
          quality
        }
        getOdd {
          id
          itemsPercents
          itemsOdds {
            id
            name
            quality
            price
            chance
          }
          hashId
        }
      }
    }

    ${FRAGMENT_CASE_FILEDS}
  `,
  fragments: {
    getCase: FRAGMENT_CASE_FILEDS,
  },
};

interface Props {
  id?: number;
}

export const useCaseInput = () => {
  const { url } = useParams();

  return {
    url,
  };
};

export default ({ id }: Props) => {
  let input = {};

  if (!id) {
    input = useCaseInput();
  } else {
    input = _.assign({}, input, {
      id,
    });
  }

  return useQuery<Data, Variables>(GET_CASE.query, {
    variables: {
      input,
    },
  });
};
