import dayjs from 'dayjs';

import toInteger from 'lodash/toInteger';

import { i18Next } from '../../i18n';

const UserController = {
  createdFormat: {
    read(_root: any, { readField }) {
      const createdAt = readField('createdAt');

      let formatDate = '';
      const date = dayjs(createdAt);

      if (dayjs().diff(date, 'weeks') > 0) {
        const week = dayjs().diff(date, 'weeks');

        formatDate = `${week} ${i18Next.t('week', {
          count: toInteger(week),
        })}`;
      } else if (dayjs().diff(date, 'days') > 0) {
        const days = dayjs().diff(date, 'days');

        formatDate = `${days} ${i18Next.t('day', {
          count: toInteger(days),
        })}`;
      } else if (dayjs().diff(date, 'hours') > 0) {
        const hours = dayjs().diff(date, 'hours');

        formatDate = `${hours} ${i18Next.t('hours', {
          count: toInteger(hours),
        })}`;
      } else if (dayjs().diff(date, 'minutes') > 0) {
        const minutes = dayjs().diff(date, 'minutes');

        formatDate = `${minutes} ${i18Next.t('minutes', {
          count: toInteger(minutes),
        })}`;
      } else if (dayjs().diff(date, 'seconds') > 0) {
        const seconds = dayjs().diff(date, 'seconds');

        formatDate = `${seconds} ${i18Next.t('seconds', {
          count: toInteger(seconds),
        })}`;
      }

      return formatDate;
    },
  },
};

export default UserController;
