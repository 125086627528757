import { useState, useEffect, FunctionComponent, ReactNode } from 'react';

interface SlideProps {
  show: boolean;
  height: number;
  children?: ReactNode;
}

const Slide: FunctionComponent<SlideProps> = ({ children, show, height }) => {
  const maxHeight = show ? `${height || 0}px` : `0px`;
  const transition = `max-height 0.15s ease-${show ? 'in' : 'out'}`;

  const [style, setStyle] = useState({
    maxHeight,
    transition,
    overflow: 'hidden',
  });

  useEffect(() => {
    setStyle({
      maxHeight,
      transition,
      overflow: 'hidden',
    });
  }, [maxHeight, transition]);

  return <div style={style}>{children}</div>;
};

export default Slide;
