import { memo, FunctionComponent, SyntheticEvent, useState } from 'react';
import { Game as GameType } from '~types';
import { useTranslation } from 'react-i18next';
import { useSubscription, gql } from '@apollo/client';
import { NavLink } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import playAudio from '~frontend/utils/playAudio';

import GameChange from '../../game/GameChange';
import Currency from '../../Currency';
import { GetName, SteamItemImage } from '../../item';
import { Price } from '~ui/index';

import IconStatus1 from '../../../scss/images/icon-profile-3.svg';
import IconStatus2 from '../../../scss/images/icon-profile-4.svg';
import IconStatus3 from '../../../scss/images/icon-profile-5.svg';
import IconStatus5 from '../../../scss/images/icon-profile-7.svg';
import IconStatus6 from '../../../scss/images/icon-profile-6.svg';
import IconProfile9 from '../../../scss/images/icon-profile-9.svg';
import IconProfile10 from '../../../scss/images/icon-profile-10.svg';
import IconProfile11 from '../../../scss/images/icon-profile-11.svg';
import IconProfileCase from '../../../scss/images/icon-btn-case.svg?react';
import { useOpenModal } from '~frontend/components/modals/hooks';
import { useConfig } from '~components/hooks';

interface ProfileItemComponentType {
  getGame: GameType;
  isUser: boolean;
  isProfileRight?: boolean;
}

interface ItemWrapComponentSubscriptionData {
  updateGame: GameType;
}

interface ItemWrapComponentSubscriptionVars {
  id: number;
}

const Item: FunctionComponent<ProfileItemComponentType> = ({
  getGame,
  isUser,
  isProfileRight,
}) => {
  const { t } = useTranslation();
  const [active, setActive] = useState('');
  const { onShow } = useOpenModal();
  const getConfig = useConfig();

  const onClick = (event: SyntheticEvent) => {
    if (getGame.type === 5 && getConfig.seo.distributionActive) {
      event.preventDefault();

      onShow('DistributionWinModal', {
        id: getGame.distributionGameId,
      });
    }
  };

  const onToogle = (event: SyntheticEvent) => {
    if (event.type === 'mouseover') {
      setActive('hover');
    } else {
      setActive('');
    }
  };

  useSubscription<
    ItemWrapComponentSubscriptionData,
    ItemWrapComponentSubscriptionVars
  >(
    gql`
      subscription updateGame($id: Int!) {
        updateGame(id: $id) {
          id
          status
          getStatus
          price
        }
      }
    `,
    { variables: { id: getGame?.id } },
  );

  return (
    <div
      className={`in-case-item status-${getGame?.status} ${
        getGame?.status === 1 && 'active wait'
      } ${active} ${!getGame && 'empty'}`}
      data-rar={getGame?.getItem.color}
      onMouseEnter={playAudio}
      data-audio="5.wav"
      data-volume="0.4"
    >
      {getGame && (
        <>
          <div className="in-case-item-stats">
            {getGame?.chance && (
              <div className="drop-chance">{getGame?.chance}%</div>
            )}
            <div className="in-case-cost">
              {getConfig.isEU ? (
                <>
                  <Currency />
                  <Price sum={active ? getGame?.priceSell : getGame?.price} />
                </>
              ) : (
                <>
                  <Price sum={active ? getGame?.priceSell : getGame?.price} />
                  <Currency />
                </>
              )}
            </div>
            {!isUser && getGame?.status === 1 && active ? (
              <div className="btn-sale-cost">
                {getConfig.isEU ? (
                  <>
                    <Currency />
                    <Price sum={getGame?.price} />
                  </>
                ) : (
                  <>
                    <Price sum={getGame?.price} />
                    <Currency />
                  </>
                )}
              </div>
            ) : null}
          </div>
          <div className="in-case-status-default">
            <div className="in-case-status-default-icon">
              {getGame?.status === 1 ? (
                <LazyLoadImage src={IconStatus1} alt="" />
              ) : getGame?.status === 2 ? (
                <LazyLoadImage src={IconStatus2} alt="" />
              ) : getGame?.status === 3 ? (
                <LazyLoadImage src={IconStatus3} alt="" />
              ) : getGame?.status === 4 ? (
                <LazyLoadImage src={IconStatus1} alt="" />
              ) : getGame?.status === 5 ? (
                <LazyLoadImage src={IconStatus5} alt="" />
              ) : getGame?.status === 6 ? (
                <LazyLoadImage src={IconStatus6} alt="" />
              ) : getGame?.status === 7 ? (
                <LazyLoadImage src={IconStatus3} alt="" />
              ) : getGame?.status === 8 ? (
                <LazyLoadImage src={IconStatus3} alt="" />
              ) : (
                getGame?.status === 9 && (
                  <LazyLoadImage src={IconStatus1} alt="" />
                )
              )}
            </div>
            <div className="in-case-status-text">{t(getGame?.getStatus)}</div>
          </div>
        </>
      )}
      <div className="in-case-item-wrap">
        <div className="img">
          <div className="in-case-item-bg" />
          {getGame && (
            <>
              {[4, 9].includes(getGame?.status) ? (
                <div className="img-contracts">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enableBackground="new 0 0 20 20"
                    height="32px"
                    viewBox="0 0 20 20"
                    width="32px"
                    fill="#8847FF"
                  >
                    <g>
                      <rect fill="none" height="20" width="20"></rect>
                      <path d="M7.45,4.58l-0.5-0.87C7.73,3.33,8.59,3.09,9.5,3.03v1C8.77,4.09,8.08,4.28,7.45,4.58z M15.97,9.5h1 c-0.06-0.91-0.3-1.77-0.69-2.55l-0.87,0.5C15.72,8.08,15.91,8.77,15.97,9.5z M12.55,4.58l0.5-0.87c-0.78-0.38-1.64-0.62-2.55-0.69 v1C11.23,4.09,11.92,4.28,12.55,4.58z M6.58,5.08l-0.5-0.87c-0.74,0.5-1.37,1.14-1.87,1.87l0.87,0.5C5.48,5.99,5.99,5.48,6.58,5.08 z M14.92,6.58l0.87-0.5c-0.5-0.74-1.14-1.37-1.87-1.87l-0.5,0.87C14.01,5.49,14.52,5.99,14.92,6.58z M7.45,15.42l-0.5,0.87 c0.78,0.38,1.64,0.62,2.55,0.69v-1C8.77,15.91,8.08,15.72,7.45,15.42z M4.03,10.5h-1c0.06,0.91,0.3,1.77,0.69,2.55l0.87-0.5 C4.28,11.92,4.09,11.23,4.03,10.5z M10.5,15.97v1c0.91-0.06,1.77-0.3,2.55-0.69l-0.5-0.87C11.92,15.72,11.23,15.91,10.5,15.97z M5.08,13.42l-0.87,0.5c0.5,0.74,1.14,1.37,1.87,1.87l0.5-0.87C5.99,14.52,5.48,14.01,5.08,13.42z M4.58,7.45l-0.87-0.5 C3.33,7.73,3.09,8.59,3.03,9.5h1C4.09,8.77,4.28,8.08,4.58,7.45z M15.97,10.5c-0.06,0.73-0.25,1.42-0.55,2.05l0.87,0.5 c0.38-0.78,0.62-1.64,0.69-2.55H15.97z M13.42,14.92l0.5,0.87c0.74-0.5,1.37-1.14,1.87-1.87l-0.87-0.5 C14.52,14.01,14.01,14.51,13.42,14.92z M10.5,7.05h-1v4l3.17,1.9l0.5-0.82l-2.67-1.58V7.05z"></path>
                    </g>
                  </svg>
                  <span>{t(getGame?.getStatus)}</span>
                </div>
              ) : (
                <>
                  {!isUser && getGame?.status === 1 ? (
                    <div className="in-case-btns-mob">
                      <NavLink
                        className="in-case-btns-mob-case"
                        to={getGame?.getCase?.getUrl}
                        onClick={onClick}
                      >
                        <IconProfileCase />
                        <span>К кейсу</span>
                      </NavLink>

                      <GameChange
                        className="in-case-btns-mob-withdraw"
                        getGame={getGame}
                        type={3}
                      >
                        <div
                          className="icon material-icons"
                          data-icon="call_received"
                        />
                        <span>{t('Withdraw')}</span>
                      </GameChange>
                      <GameChange
                        className="in-case-btns-mob-sell"
                        getGame={getGame}
                        type={0}
                        onMouseOver={onToogle}
                        onMouseLeave={onToogle}
                      >
                        <div
                          className="icon material-icons"
                          data-icon="call_made"
                        />
                        <span>{t('Sell')}</span>
                      </GameChange>
                    </div>
                  ) : null}
                  <div className="in-case-btns">
                    <NavLink
                      className="btn-case"
                      to={getGame?.getCase?.getUrl}
                      onClick={onClick}
                    >
                      <LazyLoadImage
                        src={getGame?.getCase?.getImage}
                        alt={getGame?.getCase?.getImage}
                      />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t(`Retrieved from ${getGame?.type}`, {
                            caseName: getGame?.getCase?.getName,
                            distributionGameId: getGame?.distributionGameId,
                            battlePassId: getGame?.battlePassId,
                          }),
                        }}
                      />
                    </NavLink>
                    {!isUser && getGame?.status === 1 ? (
                      <>
                        <NavLink
                          className="btn-contracts"
                          to="/contract"
                          state={{ gameIds: [getGame?.id] }}
                        >
                          <LazyLoadImage src={IconProfile9} alt="" />
                          <span>{t('add_to_contract')}</span>
                        </NavLink>
                        <GameChange
                          className="btn-withdraw"
                          getGame={getGame}
                          type={3}
                        >
                          <LazyLoadImage src={IconProfile10} alt="" />
                          <span>{t('Withdraw')}</span>
                        </GameChange>
                        <GameChange
                          className="btn-sell"
                          getGame={getGame}
                          type={0}
                          onMouseOver={onToogle}
                          onMouseLeave={onToogle}
                        >
                          <LazyLoadImage src={IconProfile11} alt="" />
                          <span>{t('Sell more expensive')}</span>
                        </GameChange>
                      </>
                    ) : null}
                  </div>
                </>
              )}
              <div className="other-labels-item">
                {getGame?.getItem?.isStatTrak && (
                  <div className="stattrack">
                    <span className="fas fa-star" /> StatTrak™
                  </div>
                )}
              </div>
              <SteamItemImage
                url={getGame?.getItem.getImage}
                size="350x262"
                alt={getGame?.getItem.getName}
              />
              <div className="honey"></div>
            </>
          )}
        </div>
        <div className="in-case-info">
          {isProfileRight ? (
            <>
              <div className="in-case-info-title">{t('Best Drop')}:</div>
              <div className="in-case-item-name">
                {getGame?.getItem.getName || '-'}
              </div>
            </>
          ) : (
            <>
              <div className="in-case-item-name">
                <GetName getItem={getGame?.getItem} type={0} />
              </div>
              <div className="in-case-item-desc">
                <GetName getItem={getGame?.getItem} type={1} />{' '}
                {getGame?.getItem?.quality && `- ${getGame?.getItem?.quality}`}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Item);
