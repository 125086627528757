import { useState } from 'react';
import { useInterval } from 'react-use';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';

interface State {
  days: number;
  hours: string;
  minutes: string;
  seconds: string;
}

const format = (time: number): string => {
  let result = String(_.toInteger(time));
  if (time.toString().length < 2) {
    result = '0' + time.toString();
  }
  return result;
};

const formatTimer = (
  currentDate: Dayjs,
  date: Date | string,
  reverse: boolean,
) => {
  return {
    days: currentDate.diff(reverse ? dayjs(date) : dayjs(), 'days'),
    hours: format(
      currentDate.diff(reverse ? dayjs(date) : dayjs(), 'hours') % 24,
    ),
    minutes: format(
      currentDate.diff(reverse ? dayjs(date) : dayjs(), 'minutes') % 60,
    ),
    seconds: format(
      currentDate.diff(reverse ? dayjs(date) : dayjs(), 'seconds') % 60,
    ),
  };
};

export const useTimer = (date: Date | string, reverse?: boolean) => {
  const endDate = reverse ? dayjs() : dayjs(date);
  const currentDate = reverse ? dayjs(date) : dayjs();
  const isEnd = endDate.unix() < currentDate.unix();
  const [time, setTime] = useState<State>({
    days: 0,
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  useInterval(
    () => {
      setTime(formatTimer(endDate, date, reverse));
    },
    isEnd ? null : 1000,
  );

  if (isEnd) {
    return null;
  }

  return time;
};
