import { gql } from '@apollo/client';

export const REFETCH_STEAM = gql`
  mutation refetchSteam {
    refetchSteam {
      id
      avatar
      userName
      props
    }
  }
`;

export const REFETCH_VK = gql`
  mutation refetchVk {
    refetchVk
  }
`;

export const REFETCH_TELEGRAM = gql`
  mutation refetchTelegram {
    refetchTelegram
  }
`;
