import { FunctionComponent } from 'react';

export const Feedback: FunctionComponent = () => {
  const onClick = () => {
    if (window.jivo_api) {
      document.querySelector('jdiv').className = 'active';
      window.jivo_api.open();
    }
  };

  return (
    <div className="header__controls-feedback" onClick={onClick}>
      <div className="icon material-icons" data-icon="&#xf0e2;"></div>
    </div>
  );
};
