import { memo, FunctionComponent } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Item as ItemType } from '~types';

interface GetNameProps {
  type: number;
  getItem: ItemType;
}

export function spltiName(name: string, type = 0) {
  const words = _.toString(name).match(/([^|]*)\|(.*)/);

  words.shift();

  return _.trim(words[type] || '');
}

const GetName: FunctionComponent<GetNameProps> = ({ type, getItem }) => {
  const {
    i18n: { language },
  } = useTranslation();

  try {
    if (!getItem) {
      return null;
    }

    const getName =
      getItem?.name && getItem?.market_hash_name
        ? _.toString(language === 'ru' ? getItem?.name : getItem?.name_en)
        : getItem?.getName;

    if (_.includes(getName, 'Пропуск зрителя')) {
      return (
        <>
          {type === 0
            ? 'Пропуск зрителя'
            : _.replace(getName, 'Пропуск зрителя', '')}
        </>
      );
    } else if (_.includes(getName, 'Viewer Pass')) {
      return (
        <>
          {type === 0 ? 'Viewer Pass' : _.replace(getName, 'Viewer Pass', '')}
        </>
      );
    }

    return (
      <>{spltiName(_.replace(_.toString(getName), 'StatTrak™', ''), type)}</>
    );
  } catch (error) {
    return null;
  }
};

export default memo(GetName);
