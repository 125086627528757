import { useQuery, gql, DocumentNode, useSubscription } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { DistributionGame as DistributionGameTypes } from '~types';
import _ from 'lodash';

export const FRAGMENT_DISTRIBUTION_GAME_FILEDS = gql`
  fragment DistributionGameFields on DistributionGame {
    id
    isJoin
    usersCount
    endDate
    price
    publicHash
    distributionNumber
    distributionId
    getType
    getWinner {
      id
      userName
      avatar
    }
    getDistribution {
      id
      conditions
      description
      type
    }
    getItem {
      id
      getName
      color
      getImage
    }
  }
`;

interface Types {
  query: DocumentNode;
  fragments?: {
    getDistributionGame: DocumentNode;
  };
  subscription: DocumentNode;
}

interface Data {
  getDistributionGame: DistributionGameTypes;
}

interface Variables {
  id?: number;
}

export const GET_DISTRIBUTION_GAME: Types = {
  query: gql`
    query useGetDistributionGame($id: Int!) {
      getDistributionGame(id: $id) {
        ...DistributionGameFields
      }
    }

    ${FRAGMENT_DISTRIBUTION_GAME_FILEDS}
  `,
  subscription: gql`
    subscription updateEvent {
      updateEvent
    }
  `,
  fragments: {
    getDistributionGame: FRAGMENT_DISTRIBUTION_GAME_FILEDS,
  },
};

export const SIGN_DISTRIBUTION = gql`
  mutation signDistribution($input: SignDistributionGameInput!) {
    signDistribution(input: $input) {
      ...DistributionGameFields
    }
  }
  ${FRAGMENT_DISTRIBUTION_GAME_FILEDS}
`;

export const useGetDistributionGame = (id: number) => {
  return useQuery<Data, Variables>(GET_DISTRIBUTION_GAME.query, {
    ssr: false,
    variables: { id },
    pollInterval: 1000 * 60,
  });
};
