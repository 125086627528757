import { useQuery, gql } from '@apollo/client';
import { FRAGMENT_GAME_FILEDS } from '../../profile/items';
import { Game as GameType } from '~types';

interface GetGameVar {
  id: number;
}

interface GetGameData {
  getGame: GameType;
}

interface GetGamesVar {
  ids: number[];
}

interface GetGamesData {
  getGames: GameType[];
}

export const useGetGame = (id: number) => {
  const { data } = useQuery<GetGameData, GetGameVar>(
    gql`
      query GetGame($id: Int!) {
        getGame(id: $id) {
          ...GameFields
        }
      }

      ${FRAGMENT_GAME_FILEDS}
    `,
    {
      variables: { id },
    },
  );

  return data?.getGame;
};

export const useGetGames = (ids: number[]) => {
  const { data } = useQuery<GetGamesData, GetGamesVar>(
    gql`
      query GetGames($ids: [Int!]!) {
        getGames(ids: $ids) {
          ...GameFields
        }
      }

      ${FRAGMENT_GAME_FILEDS}
    `,
    {
      variables: { ids },
    },
  );

  return data?.getGames;
};
