import { useQuery, gql } from '@apollo/client';
import { UserFavorite as UserFavoriteType } from '~types';

import { FRAGMENT_GAME_FILEDS } from '../profile/items';
import { GET_CASE } from '../home/hooks';

export const FRAGMENT_USER_FAVORITE_FILEDS = gql`
  fragment UserFavoriteFields on UserFavorite {
    getCase {
      ...CaseFields
    }
    getGame {
      ...GameFields
    }
  }

  ${GET_CASE.fragments.getCase}
  ${FRAGMENT_GAME_FILEDS}
`;

export const GET_USER_FAVORITE = gql`
  query getOdd($userId: Int!) {
    getUserFavorite(userId: $userId) {
      ...UserFavoriteFields
    }
  }

  ${FRAGMENT_USER_FAVORITE_FILEDS}
`;

interface Data {
  getUserFavorite: UserFavoriteType;
}

export const useUserFavorite = (userId: number) => {
  return useQuery<Data>(GET_USER_FAVORITE, {
    variables: {
      userId,
    },
  });
};
