import { FunctionComponent, SyntheticEvent } from 'react';
import { useConfig } from '~components/hooks';
import dayjs from 'dayjs';
import { useCookies } from 'react-cookie';
import { useYaMetrica } from '~frontend/provider/yametrica';
import { useModal } from './hooks';
import { useTranslation } from 'react-i18next';

import Modal from './Modal';
import FreeCase from '../../scss/images/case-free.png';
import { AuthBlock } from '../AuthBlock';

export const AuthSpecial: FunctionComponent = () => {
  const getConfig = useConfig();
  const modalName = 'AuthSpecial';
  const { t } = useTranslation();
  const yametrica = useYaMetrica();
  const { onClose, isOpen, props } = useModal<{ percent: number }>(modalName);
  const [, setCookie, removeCookie] = useCookies(['modal', 'redirect']);

  const onClick = (event: SyntheticEvent<HTMLElement>) => {
    removeCookie('modal');
    setCookie('redirect', '/pay', {
      expires: dayjs().add(5, 'minutes').toDate(),
    });

    yametrica.reachGoal(event.currentTarget.getAttribute('data-reachgoal'));
  };

  const _onClose = () => {
    removeCookie('modal');

    onClose();
  };

  return (
    <Modal show={isOpen} onClose={_onClose} width={440} modalName={modalName}>
      <div className="box-modal-wrap">
        <div className="box-modal default-popup popup-free-case popup-auth-special">
          <div className="default-popup-wrap">
            <div
              className="box-modal_close arcticmodal-close"
              onClick={_onClose}
            ></div>
            <div className="balance-title">{t('popup_free_case_title')}!</div>
            <div className="balance-sub-title">
              {t('popup_free_case_sub_title')}:
            </div>
            <div className="img">
              <img src={FreeCase} alt="" />
            </div>
            <div className="auth-special-title">
              <div className="center"></div>
              <span>{t('Free case')}</span>
            </div>
            <div className="auth-special-title-seconds">
              <div className="percent">+{props?.percent}%</div>
              <div className="text">
                {t('popup_free_case_title_second_text')}!
              </div>
            </div>
            <div className="authorization">
              <AuthBlock />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
