import { PaymentCode as PaymentCodeType } from '~types';
import { useQuery, gql } from '@apollo/client';
import { createGlobalState } from 'react-use';
import { usePromo } from '~components/pay/PromoCode';
import { addPercent, convertNumber, getPercent, number } from '~ui/Price';
import { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { useConfig } from '~frontend/components/hooks';

export const usePaymentCodeValue = createGlobalState<any>('');
export const useEmailValue = createGlobalState<any>('');
export const useAmountValue = createGlobalState<number>(0);
export const useSetPayMethod = createGlobalState<boolean>(false);

type UsePaymentCode = PaymentCodeType | null | undefined;
type IUseAmount = [number, (state: number) => void, number, number];

export const useAmount = (): IUseAmount => {
  const getConfig = useConfig();
  let minSum = getConfig?.isEU ? 1 : 1500;
  if (getConfig.minDepositSum > 0) minSum = getConfig.minDepositSum;

  const [amount, setAmount] = useAmountValue();
  // disable bonuses for payments
  // const prize = useMemo(() => {
  // if (getConfig?.isRU) {
  //   if (amount >= 200 && amount < 400) {
  //     return 3;
  //   } else if (amount >= 400 && amount < 600) {
  //     return 4;
  //   } else if (amount >= 600 && amount < 1000) {
  //     return 5;
  //   } else if (amount >= 1000 && amount < 2500) {
  //     return 6;
  //   } else if (amount >= 2500) {
  //     return 10;
  //   }
  // } else {
  //   if (amount >= 5 && amount < 20) {
  //     return 3;
  //   } else if (amount >= 20 && amount < 50) {
  //     return 4;
  //   } else if (amount >= 50 && amount < 100) {
  //     return 5;
  //   } else if (amount >= 100 && amount < 250) {
  //     return 6;
  //   } else if (amount >= 250) {
  //     return 10;
  //   }
  // }
  //   return 0;
  // }, [amount, getConfig?.isRU]);

  const prize = 0;

  return [amount, setAmount, prize, minSum];
};

export const usePaymentCode = (): UsePaymentCode => {
  const [, setAmount] = useAmount();
  const [paymentCode] = usePaymentCodeValue();

  const { data, error } = useQuery(
    gql`
      query getPaymentCode($input: PaymentCodeInput!) {
        getPaymentCode(input: $input) {
          id
          getSum
          code
          paymentCardId
        }
      }
    `,
    {
      variables: {
        input: { paymentCode },
      },
      ssr: false,
    },
  );

  useEffect(() => {
    if (_.size(paymentCode) > 5) {
      if (data?.getPaymentCode) {
        return setAmount(convertNumber(data?.getPaymentCode?.getSum));
      }
      return setAmount(0);
    }
  }, [data?.getPaymentCode, paymentCode, setAmount]);

  if (error) {
    return null;
  }

  return data?.getPaymentCode;
};

export const useReceivedSum = ({ getAmount, prize = 0, isPercent = false }) => {
  if (isPercent) {
    prize = convertNumber(getPercent(number(getAmount), prize));
  }

  prize = [299, 9.9].includes(getAmount) ? 1 : prize;

  let [getPromo] = usePromo();

  if ([299, 9.9].includes(getAmount)) {
    getPromo = null;
  }

  const percent = getPromo?.percent || 0;
  const paySum = getPromo?.paySum || 0;
  const maxPaySum = getPromo?.maxPaySum || 0;
  const promoMoney = getPromo?.balance || 0;

  let receivedSum = getAmount;

  if (promoMoney > 0) {
    receivedSum = receivedSum + prize + promoMoney;
  } else {
    receivedSum = convertNumber(addPercent(number(getAmount), percent)) + prize;
  }

  if (paySum > getAmount || (maxPaySum > 0 && getAmount >= maxPaySum)) {
    return getAmount + prize;
  }

  return receivedSum;
};
