import { useQuery, gql } from '@apollo/client';
import { Odd as OddType } from '~types';

import _ from 'lodash';

export const FRAGMENT_ODD_FILEDS = gql`
  fragment OddFields on Odd {
    id
    itemsPercents
    updatedAt
    createdAt
    status
    hashId
    getCase {
      id
      getImage
      getName
      price
      priceOld
      color
    }
    itemsOdds {
      id
      name
      price
      chance
      lowerRange
      upperRange
    }
    getItems {
      id
      market_hash_name
      getImage
      color
    }
  }
`;

export const GET_ODD = gql`
  query getOdd($hashId: String!) {
    getOdd(hashId: $hashId) {
      ...OddFields
    }
  }

  ${FRAGMENT_ODD_FILEDS}
`;

interface Data {
  getOdd: OddType;
}

export const useOdd = (hashId: string) => {
  return useQuery<Data>(GET_ODD, {
    variables: {
      hashId,
    },
  });
};
