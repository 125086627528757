import { FunctionComponent, useCallback } from 'react';
import { Config as ConfigType } from '~types';
import { useQuery, gql } from '@apollo/client';
import { useCookies } from 'react-cookie';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useYaMetrica } from '~frontend/provider/yametrica';

import Modal from './Modal';
import { useModal, useOpenModal } from './hooks';

import Money from '../../scss/images/money.png';
import { useTimer } from '../hooks';

interface LackyModalData {
  getConfig: ConfigType & {
    promoWeek: {
      dateEndFormat?: string;
    };
  };
}

const LackyModal: FunctionComponent = () => {
  const modalName = 'LackyModal';
  const [cookies, setCookie, removeCookie] = useCookies([
    'LackyModal',
    'promoCodeValue',
  ]);
  const yametrica = useYaMetrica();
  const { t } = useTranslation();

  const { onClose, isOpen } = useModal(modalName);

  const { onShow } = useOpenModal();

  const viewModal = useCallback(
    (event, { type, reachgoal } = event.currentTarget.dataset) => {
      if (event) {
        event.preventDefault();
      }

      if (!type) {
        throw Error('Не указан тип');
      }

      setCookie('LackyModal', 'true', {
        expires: dayjs(promoWeek?.dateEnd).toDate(),
      });

      onShow(type, { reachgoal, promoCode: promoWeek?.items });

      onClose();
    },
    [onShow],
  );

  const { loading, error, data } = useQuery<LackyModalData>(
    gql`
      query LackyModal {
        getConfig {
          promoWeek {
            id
            items
            dateEnd
            percent
            dateEndFormat @client
          }
        }
      }
    `,
    {
      onCompleted: ({ getConfig: { promoWeek } }) => {
        if (!cookies?.LackyModal && promoWeek) {
          removeCookie('promoCodeValue');

          onShow('LackyModal');

          yametrica.reachGoal('seenalgorithmpopup');
        }
      },
      ssr: false,
    },
  );

  const promoWeek = data?.getConfig?.promoWeek;

  const onClick = () => {
    onClose();

    setCookie('LackyModal', 'false', {
      expires: dayjs(promoWeek?.dateEnd).toDate(),
    });

    yametrica.reachGoal('usedalgorithmpopup');
  };

  if (loading || !promoWeek) {
    return null;
  } else if (error) {
    return <>LackyModal | Error! {error.message}</>;
  }

  return (
    <Modal show={isOpen} onClose={onClick} modalName={modalName}>
      <div className="box-modal-wrap">
        <div className="box-modal box-modal-balance default-popup popup-lacky">
          <div className="default-popup-wrap">
            <div className="balance-title">{t('You are lucky!')}</div>
            <div
              className="box-modal_close arcticmodal-close"
              onClick={onClick}
            ></div>
            <div className="img">
              <img src={Money} alt="" />
            </div>
            <div className="promo-text">
              <div className="text">
                {t('Last day')}
                <br />
                {t('promo code works')}
              </div>
              <div className="num">+{promoWeek.percent}%</div>
            </div>
            <div className="promo-code">
              <input type="text" value={promoWeek.items} disabled />
            </div>
            <div className="balance-btns">
              <a
                className="btn-pay"
                href="#"
                onClick={viewModal}
                data-type="FundsModal"
                data-reachgoal="LackyModalDeposit"
              >
                <div className="top"></div>
                <div className="top-2"></div>
                <div className="bot"></div>
                <div className="bot-2"></div>
                <div className="shadow"></div>
                <div className="shadow-2"></div>
                <div className="text">{t('Pay')}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LackyModal;
