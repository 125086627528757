import { SyntheticEvent, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useYaMetrica } from '~frontend/provider/yametrica';
import Currency from '~components/Currency';
import { Price } from '~ui/index';
import AddBalanceIcon from '../../scss/images/icon-profile.svg?react';
import IconBalanceBattlePass from '../../scss/images/icon-balance-battlepass.svg';

import { useProfile } from '../hooks';
import { useClickAway, useToggle } from 'react-use';
import { Menu } from './Menu';
import { useConfig } from '~components/hooks';

const HeaderProfile = () => {
  const getProfile = useProfile();
  const yametrica = useYaMetrica();
  const getConfig = useConfig();

  const reachGoal = (event: SyntheticEvent<HTMLElement>) => {
    yametrica.reachGoal(event.currentTarget.getAttribute('data-reachgoal'));
  };

  return (
    <div className="header-profile">
      <Link
        to="/pay"
        className={`header-profile__cost`}
        onClick={reachGoal}
        data-reachgoal="add_funds_header"
      >
        <div className="header-profile__cost-val">
          {getConfig.isEU ? (
            <>
              <Currency />
              <Price sum={getProfile?.money} />
            </>
          ) : (
            <>
              <Price sum={getProfile?.money} />
              <Currency />
            </>
          )}
        </div>
        <div className="header-profile__cost-game-val">
          <Price sum={getProfile?.battlePassCoins} />
          <img src={IconBalanceBattlePass} />
        </div>
        <div className="header-profile__btn">
          <AddBalanceIcon />
        </div>
      </Link>
    </div>
  );
};

export const Profile = () => {
  const ref = useRef(null);
  const [isAcitve, toogle] = useToggle(false);
  const getProfile = useProfile();

  useClickAway(ref, () => {
    toogle(false);
  });

  return (
    <>
      <HeaderProfile />

      <div
        className={`header-profile-user ${isAcitve && 'active'}`}
        onClick={toogle}
        ref={ref}
      >
        <div className="header-profile-user__header">
          <div className="header-profile-user__ava">
            <img src={getProfile.avatar} alt={getProfile.avatar} />
            <div
              className="icon material-icons"
              data-icon="&#xe5c5;"
            ></div>
          </div>
        </div>
        <div className="header-profile-user__dropdown">
          <Menu />
        </div>
      </div>
      <div className="header-profile-user-mobile">
        <Link to="/profile">
          <img src={getProfile?.avatar} />
        </Link>
      </div>
    </>
  );
};
