import { useQuery, gql, DocumentNode, useSubscription } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  EventUsers as EventUsersType,
  EventUsersFilters as EventUsersFiltersType,
} from '~types';
import _ from 'lodash';

const FRAGMENT_EVENT_USERS_FILEDS = gql`
  fragment EventUserFields on EventUser {
    id
    spent
    countOpen
    getUser {
      id
      avatar
      userName
    }
  }
`;

interface Types {
  query: DocumentNode;
  fragments?: {
    getEvent: DocumentNode;
  };
  subscription: DocumentNode;
}

interface Data {
  getEventUsers: EventUsersType;
}

interface Variables {
  eventId: number;
  filters: EventUsersFiltersType;
}

export const GET_EVENT: Types = {
  query: gql`
    query useGetEventUsers($eventId: Int!, $filters: EventUsersFilters!) {
      getEventUsers(eventId: $eventId, filters: $filters) {
        count
        getEventUsers {
          ...EventUserFields
        }
      }
    }

    ${FRAGMENT_EVENT_USERS_FILEDS}
  `,
  subscription: gql`
    subscription updateEvent {
      updateEvent
    }
  `,
  fragments: {
    getEvent: FRAGMENT_EVENT_USERS_FILEDS,
  },
};

export const useGetEventUsers = (variables: Variables) => {
  const query = useQuery<Data, Variables>(GET_EVENT.query, {
    variables,
  });

  return query?.data?.getEventUsers;
};
