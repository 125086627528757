import { FunctionComponent, SyntheticEvent } from 'react';
import { usePageLeave } from 'react-use';
import dayjs from 'dayjs';
import { useConfig } from '~components/hooks';
import { useCookies } from 'react-cookie';
import { useYaMetrica } from '~frontend/provider/yametrica';
import { useModal } from './hooks';
import { useTranslation } from 'react-i18next';

import Modal from './Modal';

import FreeCase from '../../scss/images/case-free.png';

const ForgotModal: FunctionComponent = () => {
  const getConfig = useConfig();

  const modalName = 'ForgotModal';
  const { t } = useTranslation();
  const yametrica = useYaMetrica();
  const { onClose, isOpen, onShow, props } = useModal<{ title?: string }>(
    modalName,
  );
  const [cookies, setCookie] = useCookies([modalName, 'redirect']);

  usePageLeave(() => {
    if (!cookies?.ForgotModal) {
      onShow();
    }
  }, [cookies?.ForgotModal as never]);

  const onClick = (event: SyntheticEvent<HTMLElement>) => {
    setCookie('ForgotModal', 'true', {
      expires: dayjs().add(1, 'month').toDate(),
    });

    setCookie('redirect', '/case/free', {
      expires: dayjs().add(5, 'minutes').toDate(),
    });

    if (event?.currentTarget.getAttribute('data-reachgoal')) {
      yametrica.reachGoal(event?.currentTarget.getAttribute('data-reachgoal'));
    }
  };

  const _onClose = () => {
    setCookie('ForgotModal', 'true', {
      expires: dayjs().add(1, 'month').toDate(),
    });

    onClose();
  };

  return (
    <Modal show={isOpen} onClose={_onClose} width={400} modalName={modalName}>
      <div className="box-modal-wrap">
        <div
          className={`box-modal box-modal-balance default-popup popup-lacky popup-free-case modal-auth-1`}
          onClick={_onClose}
        >
          <div className="default-popup-wrap">
            <div className="balance-title">
              {t(props?.title || 'Free case')}
            </div>
            <div className="balance-sub-title">
              {t('Open your free case without a deposit')}
            </div>
            <div
              className="box-modal_close arcticmodal-close"
              onClick={_onClose}
            ></div>
            <div className="img">
              <img src={FreeCase} alt={t('Free case')} />
            </div>
            <div className="popup-free-text">
              {t('Log in and do it right now!')}
            </div>
            <div className="authorization">
              <div className="open-cases-btns">
                <a
                  className="advantages-sign-in-steam"
                  href="/auth/steam"
                  onClick={onClick}
                  data-reachgoal="steam_auth"
                >
                  <div className="small-text">{t('Sign in through')}</div>
                  <div className="text">Steam</div>
                  <div className="top"></div>
                  <div className="bot-1"></div>
                  <div className="bot-2"></div>
                </a>
              </div>
              <div className="authorization-social">
                {getConfig?.hostname !== 'cscase.com' && (
                  <a
                    className="vk"
                    href="/auth/vk"
                    onClick={onClick}
                    data-reachgoal="vk_auth"
                  ></a>
                )}

                {getConfig?.hostname === 'cscase.com' && (
                  <a
                    className="google"
                    href="/auth/google"
                    onClick={onClick}
                    data-reachgoal="g_auth"
                  ></a>
                )}

                <a
                  className="fb"
                  href="/auth/fb"
                  onClick={onClick}
                  data-reachgoal="fb_auth"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ForgotModal;
