import { gql, useQuery } from '@apollo/client';
import { Profile } from 'graphql';

export const FRAGMENT_EXTERNAL_ACCOUNT_FILEDS = gql`
  fragment ExternalAccountFields on ExternalAccount {
    id
    avatar
    accountId
    userName
    service
    getUrl
    props
  }
`;

export const GET_EXTERNAL_ACCOUNTS = gql`
  query useGetExternalAccounts {
    getProfile {
      id
      getExternalAccounts {
        ...ExternalAccountFields
      }
      getSteam @client
      getVk @client
    }
  }

  ${FRAGMENT_EXTERNAL_ACCOUNT_FILEDS}
`;

interface Data {
  getProfile: Profile;
}

export const useGetExternalAccounts = () => {
  return useQuery<Data>(GET_EXTERNAL_ACCOUNTS);
};
