import { FunctionComponent, memo } from 'react';

interface Props {
  fon?: boolean;
}

export const Loader: FunctionComponent<Props> = memo(({ fon = true }) => {
  if (!fon) {
    return (
      <div className="loader-item">
        <div className="loader-item-bg"></div>
      </div>
    );
  }

  return (
    <div className="loader">
      <div className="loader-item">
        <div className="loader-item-bg"></div>
      </div>
    </div>
  );
});
