import { useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';

import _ from 'lodash';
import update from 'immutability-helper';

interface StatisticsData {
  getStatistics: Statistics;
}

interface StatisticsSubscribeData {
  updateStatistics: Statistics;
}

export const useGetStatistics = () => {
  const { loading, error, data, subscribeToMore } =
    useQuery<StatisticsData>(gql`
      query Statistics {
        getStatistics {
          online
          usersCount
          gamesCount
        }
      }
    `);

  useEffect(() => {
    const unsubscribe = subscribeToMore<StatisticsSubscribeData>({
      document: gql`
        subscription updateStatistics {
          updateStatistics {
            online
            usersCount
            gamesCount
          }
        }
      `,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        const data = _.pickBy(
          subscriptionData.data.updateStatistics,
          _.identity,
        );

        return update(prev, {
          getStatistics: { $merge: data },
        });
      },
    });

    return () => unsubscribe();
  }, [subscribeToMore]);

  return { loading, error, data, subscribeToMore };
};
