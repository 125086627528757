import { memo, FunctionComponent, useState, useMemo } from 'react';
import { Button } from '~ui/index';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useQuery, gql, DocumentNode } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { HistoryInput as HistoryInputTypes, ActiveGames, Game } from '~types';

import { toast } from 'react-toastify';
import { useConfig } from '~components/hooks';
import Item from './Item';
import { SellAllItems } from '../index';
import playAudio from '~frontend/utils/playAudio';
import { Loader } from '../../Loader';
import IconEmpty from '../../../scss/images/icon-profile-empty.svg';
import { useToggle, useUpdateEffect } from 'react-use';
import { copyToClipboard } from '~frontend/utils/clickboard';

export const FRAGMENT_ITEM_FILEDS = gql`
  fragment ItemFields on Item {
    id
    color
    getImage
    getName
    price
    isStatTrak
    quality
  }
`;

export const FRAGMENT_GAME_FILEDS = gql`
  fragment GameFields on Game {
    id
    status
    getStatus
    price
    priceSell
    userId
    chance
    type
    eventId
    battlePassId
    distributionGameId
    getItem {
      ...ItemFields
    }
    getCase {
      id
      getName
      getUrl
      getImage
      type
      seo
    }
  }

  ${FRAGMENT_ITEM_FILEDS}
`;

interface Types {
  query: DocumentNode;
  fragments: {
    games: DocumentNode;
  };
}

export const GET_HISTORY: Types = {
  query: gql`
    query GetHistory($input: HistoryInput!) {
      getHistory(input: $input) {
        count
        getGames {
          ...GameFields
        }
      }
    }

    ${FRAGMENT_GAME_FILEDS}
  `,
  fragments: {
    games: FRAGMENT_GAME_FILEDS,
  },
};

interface ItemsType {
  isUser?: boolean;
  userId: number;
}

interface ItemsData {
  getHistory: ActiveGames;
  getStats: Stats;
}

interface ItemsVar {
  input: HistoryInputTypes;
}

interface IItemsList {
  count: number;
  loading: boolean;
  isUser: boolean;
  getGames: Game[];
}

const ItemsList: FunctionComponent<IItemsList> = memo(
  ({ count, loading, getGames, isUser }) => {
    const { t } = useTranslation();
    const getHistory = useMemo(() => {
      return _.map(getGames, getGame => (
        <Item getGame={getGame} key={getGame?.id} isUser={isUser} />
      ));
    }, [getGames, isUser]);

    return (
      <div className="in-case-wrap">
        {loading ? (
          <Loader />
        ) : count === 0 ? (
          <div className="in-case-empty">
            <div className="empty">
              <img src={IconEmpty} alt="" />
              <div className="text">
                {t('Its time to beat out the top skins!')}
                <br />
                {t('For this, only')}
              </div>
              <Link className="btn btn-violet" to="/case/free">
                {t('Open free case')}
              </Link>
            </div>
          </div>
        ) : (
          <>{getHistory}</>
        )}
      </div>
    );
  },
);

export const Items: FunctionComponent<ItemsType> = memo(
  ({ isUser, userId }: ItemsType) => {
    const [isAwait, toggle] = useToggle(false);
    const [isCopy, toggleCopy] = useToggle(false);
    const { t } = useTranslation();
    const getConfig = useConfig();
    const profileUrl = `https://${getConfig?.hostname}/user/${userId}`;

    const [limit, setLimit] = useState(15);

    const {
      loading,
      error,
      data,
      fetchMore,
      networkStatus,
      refetch,
      variables,
    } = useQuery<ItemsData, ItemsVar>(GET_HISTORY.query, {
      notifyOnNetworkStatusChange: true,
      defaultOptions: {
        variables: {
          input: {
            offset: 0,
            limit,
            userId,
            isAwait,
          },
        },
      },
    });

    useUpdateEffect(() => {
      setLimit(15);
      refetch({
        input: {
          offset: 0,
          limit,
          userId,
          isAwait,
        },
      });
    }, [userId, isAwait]);

    const getGames = data?.getHistory?.getGames;
    const count = data?.getHistory?.count;
    const currentLength = _.size(getGames);

    const loadInv = async () => {
      try {
        const { data } = await fetchMore<ItemsData, ItemsVar>({
          variables: {
            input: {
              ...variables.input,
              offset: currentLength,
            },
          },
        });

        const getGames = data?.getHistory?.getGames;

        setLimit(currentLength + _.size(getGames));

        document.querySelector('.footer').scrollIntoView({
          block: 'end',
          behavior: 'smooth',
        });
      } catch (error) {
        toast.error(
          <>
            <div className="notify-title">{t('Error')}!</div>
            <div className="notify-text">{t(error.message)}</div>
          </>,
        );
      }
    };

    const onClick = () => {
      copyToClipboard(`https://${getConfig?.hostname}/user/${userId}`);
      toggleCopy();

      setTimeout(() => {
        toggleCopy(false);
      }, 1000);
    };

    if (error) {
      return <>Items | Error! {error.message}</>;
    }

    return (
      <>
        <div className="in-case-title">
          <span>{isUser ? t('User items') : t('Your items')}</span>
          {!isUser && (
            <div className="btn-fast-open">
              <span
                dangerouslySetInnerHTML={{ __html: t('profile btn-fast-open') }}
              />
              <label className="switch" htmlFor="checkbox">
                <input
                  type="checkbox"
                  id="checkbox"
                  defaultChecked={isAwait}
                  onChange={toggle}
                />
                <div className="switch-round"></div>
              </label>
            </div>
          )}
          {!isUser && <SellAllItems userId={userId} />}

          <div className="input-gradient input-copy" onClick={onClick}>
            <div className="icon material-icons" data-icon="&#xe157;"></div>
            <input type="text" disabled />
            <div className="fake-input">{profileUrl}</div>
            <div className="fake-label"></div>
            <div className="icon material-icons copy" data-icon="content_copy">
              {isCopy && <span>{t('Copied')}</span>}
            </div>
          </div>
        </div>

        <ItemsList
          loading={loading && _.includes([2], networkStatus)}
          count={count}
          getGames={getGames}
          isUser={isUser}
        />

        {count > 15 && currentLength < count && (
          <Button
            data-audio="6.mp3"
            onMouseEnter={playAudio}
            loading={loading}
            className="btn btn-blue btn-load"
            onClick={loadInv}
          >
            {!loading && (
              <>
                {t('Show more')}
                <i className="fas fa-redo-alt" />
              </>
            )}
          </Button>
        )}
      </>
    );
  },
);
