import { FunctionComponent, SyntheticEvent } from 'react';
import { usePageLeave } from 'react-use';
import { useCookies } from 'react-cookie';
import { useYaMetrica } from '~frontend/provider/yametrica';
import { useModal } from './hooks';
import { useTranslation } from 'react-i18next';

import Modal from './Modal';

import Img from '../../scss/images/popup-bonus-open-5-img.png';
import { useLocation, useNavigate } from 'react-router';
import { useProfile } from '../hooks';

export const ExitPopup: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const getProfile = useProfile();

  const modalName = 'ExitPopup';
  const { t } = useTranslation();
  const yametrica = useYaMetrica();
  const { onClose, isOpen, onShow } = useModal(modalName);
  const [cookies, , removeCookie] = useCookies([modalName]);

  usePageLeave(() => {
    if (cookies?.ExitPopup && getProfile?.getWheelStep > 0) {
      onShow();
    }
  }, [cookies?.ExitPopup as never]);

  const onClick = (event: SyntheticEvent<HTMLElement>) => {
    if (event?.currentTarget.getAttribute('data-reachgoal')) {
      yametrica.reachGoal(event?.currentTarget.getAttribute('data-reachgoal'));
    }
    removeCookie('ExitPopup', {
      path: '/',
    });
    if (location.pathname.includes('wheel')) {
      onClose();

      if (document.querySelector('.circle-game-btn')) {
        (
          document.querySelector('.circle-game-btn') as HTMLElement
        ).dispatchEvent(
          new MouseEvent('mousedown', {
            bubbles: true,
            cancelable: true,
            view: window,
          }),
        );
      }
    } else {
      navigate('/wheel');
    }
  };

  const _onClose = () => {
    removeCookie('ExitPopup', {
      path: '/',
    });

    yametrica.reachGoal('PlayCircleExitPopup');

    onClose();
  };

  return (
    <Modal show={isOpen} onClose={_onClose} width={400} modalName={modalName}>
      <div className="box-modal modal-blue modal-blue-sec">
        <div className="box-modal_close arcticmodal-close" onClick={_onClose}>
          <div className="icon material-icons">close</div>
        </div>
        <div className="modal-blue-title">Не спеши уходить!</div>
        <div className="modal-blue-title-sec">
          Сыграй в колесо бонусов и забери свой приз
        </div>
        <img className="popup-img-width-full" src={Img} />
        <div className="modal-blue-btn-wrap">
          <button className="btn-yellow" onClick={onClick}>
            Крутить колесо
          </button>
        </div>
      </div>
    </Modal>
  );
};
