import { FieldFunctionOptions, Reference } from '@apollo/client';

export const BattlePassLevel = {
  getRar: {
    read(_root: any, { readField }: FieldFunctionOptions) {
      const getColor = readField('getColor');

      if (getColor) {
        return getColor;
      }
      const getItem = readField('getItem');
      const getCase = readField('getCase');

      if (getItem) {
        return readField('color', getItem as Reference);
      } else if (getCase) {
        return readField('color', getCase as Reference);
      }

      return 'ffd700';
    },
  },
};
