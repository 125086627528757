import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import toInteger from 'lodash/toInteger';

import { i18Next } from '../../i18n';

const Promo = {
  dateEndFormat: {
    read(_root: any, { cache, readField }) {
      try {
        const getPromo = cache.readFragment({
          id: `Promo:${readField('id')}`,
          fragment: gql`
            fragment getPromo on Promo {
              id
              dateEnd
            }
          `,
        });

        const dateEnd = getPromo?.dateEnd;

        let formatDate = '';
        const date = dayjs(dateEnd);

        if (date.diff(dayjs(), 'days') > 0) {
          const days = date.diff(dayjs(), 'days');

          formatDate = `${days} ${i18Next.t('day', {
            count: toInteger(days),
          })}`;
        } else if (date.diff(dayjs(), 'hours') > 0) {
          const hours = date.diff(dayjs(), 'hours');

          formatDate = `${hours} ${i18Next.t('hours', {
            count: toInteger(hours),
          })}`;
        } else if (date.diff(dayjs(), 'minutes') > 0) {
          const minutes = date.diff(dayjs(), 'minutes');

          formatDate = `${minutes} ${i18Next.t('minutes', {
            count: toInteger(minutes),
          })}`;
        } else if (date.diff(dayjs(), 'seconds') > 0) {
          const seconds = date.diff(dayjs(), 'seconds');

          formatDate = `${seconds} ${i18Next.t('seconds', {
            count: toInteger(seconds),
          })}`;
        }

        return formatDate;
      } catch (error) {
        return '';
      }
    },
  },
};

export default Promo;
