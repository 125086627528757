import {
  useCallback,
  FunctionComponent,
  useMemo,
  SyntheticEvent,
  useRef,
} from 'react';
import { Game as GameType } from '~types';
import { useQuery, gql, DocumentNode } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  createGlobalState,
  useUpdateEffect,
  useDeepCompareEffect,
} from 'react-use';
import _ from 'lodash';
import update from 'immutability-helper';

export const useToggle = createGlobalState<boolean>(true);

import { NewGamesList, useNewGame, useTopDrop } from './NewGamesList';
import Game from './Game';

import iconLiveStarDefault from '../../../scss/images/icon-live-star-default.svg';
import iconLiveStar from '../../../scss/images/icon-live-star.svg';
import iconLiveAllStarDefault from '../../../scss/images/icon-live-all-default.svg';
import iconLiveAll from '../../../scss/images/icon-live-all.svg';

export const FRAGMENT_GAME_FILEDS = gql`
  fragment LiveGameFields on Game {
    id
    type
    userId
    chance
    price
    createdAt
    gamePrice
    type
    distributionGameId
    battlePassId
    eventId
    getUser {
      id
      userName
      avatar
      isHidden
    }
    getItem {
      id
      getImage
      name
      name_en
      market_hash_name
      getName
      color
      isStatTrak
    }
    getCase {
      id
      getImage
      getUrl
      type
    }
  }
`;

type FragmentsTypes = {
  games: DocumentNode;
};

export const fragments: FragmentsTypes = {
  games: FRAGMENT_GAME_FILEDS,
};

const GET_LIVE_GAMES = gql`
  query Live($isBest: Boolean!) {
    getLiveGames(isBest: $isBest) {
      ...LiveGameFields
    }
  }

  ${FRAGMENT_GAME_FILEDS}
`;

interface LiveGameVars {
  isBest: boolean;
}

interface LiveGameData {
  getLiveGames: GameType[];
}

const GameList: FunctionComponent = () => {
  const [topDrop, setTopDrop] = useTopDrop();
  const [isBest] = useToggle();
  const [getGame] = useNewGame();

  const { loading, error, data, refetch, client } = useQuery<
    LiveGameData,
    LiveGameVars
  >(GET_LIVE_GAMES, {
    variables: {
      isBest,
    },
    ssr: false,
    onCompleted: ({ getLiveGames }) => {
      setTopDrop(_.first(_.orderBy(getLiveGames, ['price'], ['desc'])));
    },
  });

  const getLiveGames = useMemo(() => {
    return _.map(data?.getLiveGames, game => (
      <Game key={game.id} game={game} />
    ));
  }, [data?.getLiveGames]);

  useUpdateEffect(() => {
    refetch();
  }, [isBest]);

  useDeepCompareEffect(() => {
    if (getGame) {
      client.cache.writeQuery({
        query: GET_LIVE_GAMES,
        variables: {
          isBest,
        },
        data: update(data, {
          getLiveGames: {
            $splice: [[_.size(data.getLiveGames), 1]],
          },
        }),
      });

      if (getGame?.price > topDrop?.price) {
        setTopDrop(getGame);
      }
    }
  }, [getGame || {}]);

  if (loading) {
    return <>Loading...</>;
  } else if (error) {
    return <>Live | Error! {error.message}</>;
  }

  return <>{getLiveGames}</>;
};

function Live() {
  const [topDrop] = useTopDrop();
  const topDropRef = useRef();
  const [isBest, toogle] = useToggle();
  const { t } = useTranslation();

  const onToogle = useCallback(
    async (event: SyntheticEvent) => {
      event.preventDefault();

      if (event.currentTarget.className.includes('better-drop')) {
        toogle(false);
      } else {
        toogle(true);
      }
    },
    [toogle],
  );

  return (
    <div className="top-drop-wrap">
      <div className="drop-btns">
        <a
          className={`drop-best ${isBest && 'active'}`}
          onClick={onToogle}
          href="#"
        >
          <div className="drop-best-wrap">
            <div className="badge">{t('Best drop')}</div>
            <img src={iconLiveStarDefault} alt="" />
            <img className="hover" src={iconLiveStar} alt="" />
          </div>
        </a>
        <a
          className={`drop-best better-drop ${!isBest && 'active'}`}
          href="#"
          onClick={onToogle}
        >
          <div className="drop-best-wrap">
            <div className="badge">{t('All drop')}</div>
            <img src={iconLiveAllStarDefault} alt="" />
            <img className="hover" src={iconLiveAll} alt="" />
          </div>
        </a>
      </div>
      <div className="top-drop" ref={topDropRef}>
        {topDrop && <Game game={topDrop} />}
        <NewGamesList />
        <GameList />
      </div>
    </div>
  );
}

export default Live;
