import { useState, memo, FunctionComponent } from 'react';
import { useInterval } from 'react-use';
import dayjs from 'dayjs';

interface CountDownProps {
  timer: number;
}

export const CountDown: FunctionComponent<CountDownProps> = memo(
  ({ timer }) => {
    const [unix, setTimer] = useState(timer);

    useInterval(
      () => {
        setTimer(() => unix - 1);
      },
      unix > 0 ? 1000 : null,
    );

    return (
      <>
        {dayjs.unix(unix).format('mm')}:{dayjs.unix(unix).format('ss')}
      </>
    );
  },
);
