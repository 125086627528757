import { FunctionComponent, memo } from 'react';
import { useTimer } from '~components/hooks';

interface Props {
  endDate: Date;
}

export const Timer: FunctionComponent<Props> = memo(({ endDate }) => {
  const timer = useTimer(endDate);

  if (!timer) {
    return null;
  }

  return (
    <>
      {timer?.minutes}:{timer?.seconds}
    </>
  );
});
