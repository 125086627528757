import { ActiveGames, HistoryInput } from '~types';
import { QueryHookOptions, gql, useQuery } from '@apollo/client';

interface ItemsUserData {
  getActiveHistory: ActiveGames;
}

interface ItemsUserVars {
  input: HistoryInput;
}

export const useActiveItemsUser = (
  options?: QueryHookOptions<ItemsUserData, ItemsUserVars>,
) => {
  return useQuery<ItemsUserData, ItemsUserVars>(
    gql`
      query ItemsUser($input: HistoryInput!) {
        getActiveHistory(input: $input) {
          count
          getGames {
            id
            price
            status
            getItem {
              id
              getImage
              getName
              color
            }
          }
        }
      }
    `,
    options,
    // {
    //   variables: {
    //     input: {
    //       userId: getProfile?.id,
    //       filterSearch,
    //       filterOrder: {
    //         sortField,
    //         sortOrder: filterOrder ? 'desc' : 'asc',
    //       },
    //     },
    //   },
    // },
  );
};
