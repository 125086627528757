import { FunctionComponent, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProfile } from '~components/profile/hooks';
import { useYaMetrica } from '~frontend/provider/yametrica';
import playAudio from '~frontend/utils/playAudio';

import { useOpenModal } from '../modals/hooks';

const PayMethods: FunctionComponent = () => {
  const navigate = useNavigate();
  const yametrica = useYaMetrica();
  const { onShow } = useOpenModal();
  const getProfile = useProfile();

  const reachGoal = (
    event: SyntheticEvent<HTMLElement>,
    { reachgoal } = event?.currentTarget?.dataset ||
      (event.target as HTMLElement)?.dataset,
  ) => {
    yametrica.reachGoal(reachgoal);

    if (getProfile) {
      navigate('/pay');
    }
  };

  return (
    <div
      className="footer-bot"
      data-type="FundsModal"
      data-reachgoal="add_funds_footer"
      onClick={reachGoal}
    >
      <div
        className="pay-1"
        onMouseEnter={playAudio}
        data-audio="2.mp3"
        data-volume="0.2"
      />
      <div
        className="pay-2"
        onMouseEnter={playAudio}
        data-audio="2.mp3"
        data-volume="0.2"
      />
      <div
        className="pay-3"
        onMouseEnter={playAudio}
        data-audio="2.mp3"
        data-volume="0.2"
      />
      <div
        className="pay-4"
        onMouseEnter={playAudio}
        data-audio="2.mp3"
        data-volume="0.2"
      />
      {/* <div
        className="pay-5"
        onMouseEnter={playAudio}
        data-audio="2.mp3"
        data-volume="0.2"
      /> */}
      <div
        className="pay-6"
        onMouseEnter={playAudio}
        data-audio="2.mp3"
        data-volume="0.2"
      />
    </div>
  );
};

export default PayMethods;
