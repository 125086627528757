import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import { ExternalAccount, Profile } from '~types';
import _ from 'lodash';
import toInteger from 'lodash/toInteger';

import { i18Next } from '../../i18n';
import { GET_PROFILE } from '~frontend/components/hooks';

const ProfileController = {
  createdFormat: {
    read(_root: any, { cache, readField }) {
      try {
        const getProfile = cache.readFragment({
          id: `Profile:${readField('id')}`,
          fragment: gql`
            fragment getProfile on Profile {
              id
              createdAt
            }
          `,
        });

        const createdAt = getProfile?.createdAt;

        let formatDate = '';
        const date = dayjs(createdAt);

        if (dayjs().diff(date, 'weeks') > 0) {
          const week = dayjs().diff(date, 'weeks');

          formatDate = `${week} ${i18Next.t('week', {
            count: toInteger(week),
          })}`;
        } else if (dayjs().diff(date, 'days') > 0) {
          const days = dayjs().diff(date, 'days');

          formatDate = `${days} ${i18Next.t('day', {
            count: toInteger(days),
          })}`;
        } else if (dayjs().diff(date, 'hours') > 0) {
          const hours = dayjs().diff(date, 'hours');

          formatDate = `${hours} ${i18Next.t('hours', {
            count: toInteger(hours),
          })}`;
        } else if (dayjs().diff(date, 'minutes') > 0) {
          const minutes = dayjs().diff(date, 'minutes');

          formatDate = `${minutes} ${i18Next.t('minutes', {
            count: toInteger(minutes),
          })}`;
        } else if (dayjs().diff(date, 'seconds') > 0) {
          const seconds = dayjs().diff(date, 'seconds');

          formatDate = `${seconds} ${i18Next.t('seconds', {
            count: toInteger(seconds),
          })}`;
        }

        return formatDate;
      } catch (error) {
        return '';
      }
    },
  },
  getSteam: {
    read(_root: any, { readField, cache }) {
      try {
        const getProfile = cache.readFragment({
          id: `Profile:${readField('id')}`,
          fragment: gql`
            fragment getSteam on Profile {
              id
              social
              userId
              userName
              avatar
              getExternalAccounts {
                id
                accountId
                userName
                service
                props
              }
            }
          `,
        }) as Profile;

        if (getProfile?.social === 0) {
          return {
            id: getProfile.id,
            accountId: getProfile.userId,
            userName: getProfile.userName,
            service: 0,
          };
        }

        const getExternalAccounts =
          getProfile?.getExternalAccounts as ExternalAccount[];
        const getSteam = _.find(getExternalAccounts, ['service', 0]);

        if (!getSteam) {
          return null;
        }

        return getSteam;
      } catch (error) {
        return null;
      }
    },
  },
  getVk: {
    read(_root: any, { readField, cache }) {
      try {
        const getProfile = cache.readFragment({
          id: `Profile:${readField('id')}`,
          fragment: gql`
            fragment getVk on Profile {
              id
              social
              userId
              userName
              avatar
              joinedToGroup
              getExternalAccounts {
                id
                accountId
                userName
                service
                props
              }
            }
          `,
        }) as Profile;

        if (getProfile?.social === 1) {
          return {
            id: getProfile.id,
            accountId: getProfile.userId,
            userName: getProfile.userName,
            service: 1,
            props: {
              joinedToGroup: getProfile.joinedToGroup,
            },
          };
        }

        const getExternalAccounts =
          getProfile?.getExternalAccounts as ExternalAccount[];
        const getVk = _.find(getExternalAccounts, ['service', 1]);

        if (!getVk) {
          return null;
        }

        return getVk;
      } catch (error) {
        return null;
      }
    },
  },
  getTelegram: {
    read(_root: any, { readField, cache }) {
      try {
        const getProfile = cache.readFragment({
          id: `Profile:${readField('id')}`,
          fragment: gql`
            fragment getTelegram on Profile {
              id
              social
              userId
              userName
              avatar
              joinedToGroup
              joinedToGroupTelegram
              getExternalAccounts {
                id
                accountId
                userName
                service
                props
              }
            }
          `,
        }) as Profile;

        if (getProfile?.social === 6) {
          return {
            id: getProfile.id,
            accountId: getProfile.userId,
            userName: getProfile.userName,
            service: 6,
            props: {
              joinedToGroup: getProfile.joinedToGroupTelegram,
            },
          };
        }

        const getExternalAccounts =
          getProfile?.getExternalAccounts as ExternalAccount[];
        const getTelegram = _.find(getExternalAccounts, ['service', 6]);

        if (!getTelegram) {
          return null;
        }

        return getTelegram;
      } catch (error) {
        return null;
      }
    },
  },
};

export default ProfileController;
