import { memo, FunctionComponent } from 'react';
import _ from 'lodash';
import { useConfig } from './hooks/useConfig';

interface CurrencyProps {
  isText?: boolean;
  isSymbol?: boolean;
}

const Currency: FunctionComponent<CurrencyProps> = ({ isText, isSymbol }) => {
  const getConfig = useConfig();

  if (isSymbol) {
    return <>{getConfig?.getCurrency?.symbol}</>;
  }

  if (isText && getConfig?.getCurrency?.char.includes('RUB')) {
    return <>рублей</>;
  }

  return isText ? (
    <>{getConfig?.getCurrency?.char}</>
  ) : getConfig?.getCurrency?.char.includes('RUB') ? (
    <i aria-hidden="true" className="rub">
      ₽
    </i>
  ) : (
    <>{getConfig?.getCurrency?.symbol}</>
  );
};

export default memo(Currency);
