import _ from 'lodash';
import {
  ChangeEvent,
  FunctionComponent,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FakeInput } from '~shared/frontend/ui';
import { useOpenModal } from './modals/hooks';

interface Props {
  initialTradeUrl: string;
  isShow?: boolean;
  readOnly?: boolean;
}

const TradeInput: FunctionComponent<Props> = memo(
  ({ initialTradeUrl, isShow, readOnly = false }: Props) => {
    const { t } = useTranslation();
    const { onShow } = useOpenModal();
    const [tradeUrl, setTradeUrl] = useState(initialTradeUrl);
    const [activeTradeUrlInput, setActiveTradeUrlInput] = useState(
      _.size(tradeUrl) !== 0,
    );

    const isCheck = useMemo(() => {
      return _.toString(tradeUrl).match(
        /http(?:s):\/\/steamcommunity.com\/tradeoffer\/new\/?\?partner=([0-9]+)&token=(.+)/,
      );
    }, [tradeUrl]);

    useEffect(() => {
      setActiveTradeUrlInput(_.size(tradeUrl) !== 0);
    }, [tradeUrl]);

    const onBlur = (event: ChangeEvent<HTMLInputElement>) => {
      const tradeUrl = _.trim(event.target.value);
      setTradeUrl(tradeUrl);

      if (_.size(tradeUrl) === 0) {
        setActiveTradeUrlInput(false);
      }

      if (
        isShow &&
        _.toString(tradeUrl).match(
          /http(?:s):\/\/steamcommunity.com\/tradeoffer\/new\/?\?partner=([0-9]+)&token=(.+)/,
        )
      ) {
        onShow('TradeUrlSave', {
          tradeUrl: tradeUrl,
        });
      }
    };

    return (
      <div
        className={`input-gradient ${activeTradeUrlInput && 'active'} ${
          isCheck ? 'check' : 'error'
        }`}
      >
        <div className="icon material-icons">link</div>
        <FakeInput
          value={tradeUrl}
          onBlur={onBlur}
          readOnly={readOnly}
          label={<div className="fake-label">{t('Your trade link')}</div>}
        />
        <div className="icon material-icons input-status check">
          check_circle
        </div>
        <div className="icon material-icons input-status cancel">
          cancel<span>{t('Trad-link is incorrect')}</span>
        </div>
      </div>
    );
  },
);

TradeInput.displayName = 'TradeInput';

export { TradeInput };
