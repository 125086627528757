import { useState, memo, FunctionComponent } from 'react';
import { useInterval } from 'react-use';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

interface TimeComponentTyps {
  createdAt: string;
}

const Time: FunctionComponent<TimeComponentTyps> = ({ createdAt }) => {
  const [time, setTime] = useState(null);

  useInterval(() => {
    setTime(dayjs(createdAt).fromNow());
  }, 1000);

  return time;
};

export default memo(Time);
