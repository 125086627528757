import { useQuery, gql, DocumentNode } from '@apollo/client';
import {
  DistributionGame as DistributionGameTypes,
  DistributionGameFilterInput as DistributionGameFilterInputTypes,
} from '~types';

import { FRAGMENT_DISTRIBUTION_GAME_FILEDS } from './useGetDistributionGame';

interface Types {
  query: DocumentNode;
  fragments?: {
    getDistributionGames: DocumentNode;
  };
}

interface Data {
  getDistributionGames: DistributionGameTypes[];
}

interface Variables {
  input: DistributionGameFilterInputTypes;
}

export const GET_DISTRIBUTION_GAMES: Types = {
  query: gql`
    query useGetDistributionGames($input: DistributionGameFilterInput!) {
      getDistributionGames(input: $input) {
        ...DistributionGameFields
      }
    }

    ${FRAGMENT_DISTRIBUTION_GAME_FILEDS}
  `,
  fragments: {
    getDistributionGames: FRAGMENT_DISTRIBUTION_GAME_FILEDS,
  },
};

export const useGetDistributionGames = (
  input: DistributionGameFilterInputTypes,
) => {
  return useQuery<Data, Variables>(GET_DISTRIBUTION_GAMES.query, {
    variables: { input },
    pollInterval: 1000 * 60,
  });
};
