import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { useYaMetrica } from '~frontend/provider/yametrica';
import { useModal } from '../hooks';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import { usePromo } from '~components/pay/PromoCode';

import PopupBonusOpen5 from '../../../scss/images/popup-bonus-open-5-img.png';

export const PromoModal12: FunctionComponent = () => {
  const modalName = 'PromoModal12';
  const { t } = useTranslation();
  const yametrica = useYaMetrica();
  const { onClose, isOpen, props } = useModal<{
    promoCode: string;
    promoCodeTimeOut: number;
  }>(modalName);
  const [, , setPromoCode] = usePromo();

  const _onClose = () => {
    setPromoCode(props?.promoCode, props?.promoCodeTimeOut);

    onClose();
  };

  const onClick = () => {
    yametrica.reachGoal(`onClick:${modalName}`);

    onClose();
  };

  return (
    <Modal show={isOpen} onClose={_onClose} width={412} modalName={modalName}>
      <div className="box-modal modal-blue">
        <div className="box-modal_close arcticmodal-close" onClick={_onClose}>
          <div className="icon material-icons">close</div>
        </div>
        <div className="modal-blue-title">
          {t(`${modalName} modal-blue-title`)}
        </div>
        <img className="popup-img-width-full" src={PopupBonusOpen5} />
        <div className="modal-blue-text">
          <div className="modal-blue-text-num">+2</div>
          <div
            className="modal-blue-text-name"
            dangerouslySetInnerHTML={{
              __html: t(`${modalName} modal-blue-text-name`),
            }}
          ></div>
        </div>
        <div className="modal-blue-btn-wrap" onClick={onClick}>
          <Link onClick={onClick} to="/wheel">
            <button className="btn-yellow">
              {t(`${modalName} modal-blue-btn-wrap`)}
            </button>
          </Link>
        </div>
      </div>
    </Modal>
  );
};
