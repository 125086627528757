import dayjs from 'dayjs';
import _ from 'lodash';
import {
  ChangeEvent,
  FunctionComponent,
  memo,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import Currency from '~components/Currency';
import {
  useConfig,
  useGetActiveModal,
  useGetGaTestId,
  useReceivedSum,
} from '~components/hooks';
import { useYaMetrica } from '~frontend/provider/yametrica';
import {
  FundsVariants as FundsVariantsType,
  PaymentSystem as PaymentSystemType,
  Promo as PromoType,
} from '~types';

import { gql, useApolloClient, useQuery } from '@apollo/client';

import IconSkinPercent from '../../scss/images/icon-skin-percent.svg';

interface PromoData {
  getPromo: PromoType;
}

interface PromoVars {
  input: { promoCode: string };
}

type UsePromo = [
  PromoType,
  string,
  (newValue: string, timeOut?: number) => void,
];

export const FRAGMENT_PROMO_FILEDS = gql`
  fragment PromoFields on Promo {
    id
    type
    percent
    paySum
    balance
    maxPaySum
    isHidden
    items
  }
`;

export const GET_PROMO = gql`
  query getPromo($input: PromoInput!) {
    getPromo(input: $input) {
      ...PromoFields
    }
  }

  ${FRAGMENT_PROMO_FILEDS}
`;

export function usePromo(): UsePromo {
  const [cookies, setCookie] = useCookies(['promoCodeValue']);

  const promoCode = _.toString(cookies?.promoCodeValue);

  const onSetPromoCode = (promoCode: string, timeOut = 30) => {
    setCookie('promoCodeValue', promoCode, {
      expires: dayjs().add(timeOut, 'minutes').toDate(),
      path: '/',
    });
  };

  const { data } = useQuery<PromoData, PromoVars>(
    gql`
      query getPromo($input: PromoInput!) {
        getPromo(input: $input) {
          ...PromoFields
        }
      }

      ${FRAGMENT_PROMO_FILEDS}
    `,
    {
      variables: {
        input: { promoCode },
      },
      fetchPolicy: 'network-only',
    },
  );

  return [data?.getPromo, promoCode, onSetPromoCode];
}

interface PromoCodeProps {
  activeFundVariant: FundsVariantsType;
  getAmount?: number;
  activeMethod?: PaymentSystemType;
}

export const PromoSumBlock: FunctionComponent<PromoCodeProps> = memo(
  ({ activeFundVariant, getAmount, activeMethod }) => {
    const { t } = useTranslation();

    const receivedSum = useReceivedSum({
      getAmount,
      prize: activeMethod?.type === 1 ? 0 : activeFundVariant?.prize,
      isPercent:
        activeMethod?.type === 1 ? false : activeFundVariant?.isPercent,
    });

    return (
      <div className="modal-money-info" data-type={activeMethod?.type}>
        {activeMethod?.type === 0 && (
          <div className="modal-money-info-item">
            <div className="modal-money-info-item-title">{t('You pay')}:</div>
            <div className="modal-money-info-item-cost">
              {getAmount} <Currency />
            </div>
          </div>
        )}
        <div className="modal-money-info-item">
          <div className="modal-money-info-item-title">
            {t('You are getting')}:
          </div>
          <div className="modal-money-info-item-cost">
            {receivedSum} <Currency />
          </div>
        </div>
      </div>
    );
  },
);

const PromoCode: FunctionComponent<PromoCodeProps> = ({
  activeFundVariant,
  getAmount,
}) => {
  const [_ga_ab] = useGetGaTestId();
  const yametrica = useYaMetrica();
  const [getPromo, promoCode, setPromoCode] = usePromo();
  const {
    data: { getActiveModal = null },
  } = useGetActiveModal();
  const client = useApolloClient();
  const getConfig = useConfig();
  const [getStatus, setStatus] = useState('');
  const [, togglePromoInput] = useToggle(false);
  const { t } = useTranslation();

  const isHidden = getPromo?.isHidden;
  const percent = getPromo?.percent || 0;
  const paySum = getPromo?.paySum || 0;
  const maxPaySum = getPromo?.maxPaySum > 0 ? getPromo?.maxPaySum : 100000;
  const promoMoney = getPromo?.balance || 0;

  useEffect(() => {
    if (_.size(promoCode) > 0) {
      togglePromoInput(true);
      if (getPromo) {
        yametrica.reachGoal('onSetPromoCode', promoCode);
        if (paySum > getAmount || getAmount > maxPaySum) {
          setStatus('error');
        } else {
          setStatus('check');
        }
      } else {
        setStatus('error');
      }
    } else {
      setStatus('');
    }
  }, [
    getPromo,
    promoCode,
    getAmount,
    togglePromoInput,
    yametrica,
    paySum,
    maxPaySum,
  ]);

  const promoCodeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      setPromoCode(event.target.value);
    },
    [setPromoCode],
  );

  const clearPromo = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();

      client.cache.writeQuery({
        query: GET_PROMO,
        data: {
          getPromo: null,
        },
        variables: {
          input: { promoCode: '' },
        },
      });

      setPromoCode('');
      togglePromoInput(false);
    },
    [setPromoCode, togglePromoInput],
  );

  const getPromoInfoText = useCallback(() => {
    if (!getPromo) {
      return t('Promo code is already used or not exist');
    }

    if (paySum > getAmount) {
      return t('this promo only from rub', {
        money: paySum,
        currency: getConfig?.getCurrency?.char,
      });
    } else if (getAmount > maxPaySum) {
      return t('this promo only to', {
        money: maxPaySum,
        currency: getConfig?.getCurrency?.char,
      });
    } else if (percent > 0) {
      return t('+20% to deposit with your promocode', {
        percent,
      });
    } else if (promoMoney > 0) {
      return t('+20r to deposit with your promocode', {
        money: promoMoney,
        currency: getConfig?.getCurrency?.char,
      });
    }
  }, [
    getPromo,
    paySum,
    getAmount,
    maxPaySum,
    percent,
    promoMoney,
    t,
    getConfig?.getCurrency?.char,
  ]);

  const getPromoPercentText = useCallback(() => {
    if (!getPromo) {
      return null;
    }

    if (paySum > getAmount) {
      return null;
    } else if (getAmount > maxPaySum) {
      return null;
    } else if (percent > 0) {
      return `+${percent}%`;
    } else if (promoMoney > 0) {
      return (
        <>
          +{promoMoney}
          <Currency />
        </>
      );
    }
  }, [getPromo, paySum, getAmount, maxPaySum, percent, promoMoney]);

  return (
    <div className="pay-form-method-wrap">
      <div className="skin-card skin-card-v2 pay-form-method-promocode">
        <input
          name="promoCode"
          type="text"
          value={promoCode}
          onChange={promoCodeChange}
          hidden
        />
        {!isHidden ||
        !(getActiveModal?.getModal?.getPromo?.items === promoCode) ? (
          <>
            <div
              className={`input-gradient input-gradient-promo js-input-promo ${
                _.size(promoCode) > 0 && 'active'
              } ${getStatus}`}
            >
              <div className="icon material-icons">
                <img src={IconSkinPercent} />
              </div>

              <input
                className="js-pay-input-anim"
                type="text"
                value={promoCode}
                onChange={promoCodeChange}
                autoComplete="off"
              />

              <div className="pay-user-email-label">
                {_.size(promoCode) > 0 ? t('PromoCode') : t('I have promocode')}
              </div>

              {getStatus === 'error' && (
                <div
                  className="icon material-icons cancel"
                  onClick={clearPromo}
                >
                  cancel
                  {/* <span>{getPromoPercentText()}</span> */}
                </div>
              )}
              {/* NOTE: не стал удалять - мало ли пригодится */}
              {/* {getPromoPercentText() && (
                <div className="promocode-percent">{getPromoPercentText()}</div>
              )} */}
            </div>
            {_.size(getStatus) > 0 ? (
              getPromoPercentText() ? (
                <div className="pay-promo-banner-w-percent">
                  <div className="pay-promo-banner-percent">
                    {getPromoPercentText()}
                  </div>
                  <div className="pay-promo-banner-text">
                    <div>{t('To deposit')}</div>
                    <div>{t('With your promocode')}</div>
                  </div>
                </div>
              ) : (
                <div className="pay-promo-banner-w-text">
                  <div className="pay-promo-banner-text">
                    <div>{getPromoInfoText()}</div>
                  </div>
                </div>
              )
            ) : (
              <div className="pay-promo-banner">
                <div className="pay-promo-banner-text">
                  <div>{t('Have a promo code?')}</div>
                  <div>{t('Enter it higher!')}</div>
                </div>
              </div>
            )}
          </>
        ) : (
          <a className="clear-promo" href="#" onClick={clearPromo}>
            {t('I have promocode')}
          </a>
        )}
        {/* NOTE: не стал удалять - мало ли пригодится */}
        {/* <div
          className={`pay-form-empty-promocode ${
            _.size(getStatus) > 0 ? '_active' : ''
          }`}
          // style={{ display: _.size(getStatus) > 0 ? 'block' : 'none' }}
        >
          {getStatus === 'done' ? (
            <div className="done">{getPromoInfoText()}</div>
          ) : (
            <div className="error">{getPromoInfoText()}</div>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default memo(PromoCode);
