import { useQuery, gql, useSubscription } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { BattlePass as BattlePassType } from '~types';
import _ from 'lodash';
import { createGlobalState } from 'react-use';
import { FRAGMENT_CASE_FILEDS } from '../home/hooks';

export const useNextLevelId = createGlobalState<number>(null);

export const FRAGMENT_BATTLE_PASS_USER_QUEST_FILEDS = gql`
  fragment BattlePassUserQuestFields on BattlePassUserQuest {
    id
    props
    isCompleted
    battlePassQuestId
  }
`;

const FRAGMENT_BATTLE_PASS_FILEDS = gql`
  fragment BattlePassFields on BattlePass {
    id
    endDate
    getUrl
    props
    isLaunched
    isEndend
    startDate
    isStart
  }
`;

const FRAGMENT_BATTLE_PASS_LEVEL_FILEDS = gql`
  fragment BattlePassLevelFields on BattlePassLevel {
    id
    type
    isOpen
    getDesc
    getType
    getCoins
    getColor
    getRandomItem
    level
    getMoney
    getItem {
      id
      color
      getName
      getImage
    }
    getCase {
      id
      color
      getName
      getImage
      getUrl
    }
    getRar @client
  }
`;

interface Data {
  getBattlePass: BattlePassType;
}

interface Variables {
  input: {
    id?: number;
    url?: string;
  };
}

export const GET_BATTLE_PASS = {
  mutation: gql`
    mutation getBattlePrizeLevel {
      getBattlePrizeLevel {
        id
        level
        nextLevel
        getNextLevel {
          ...BattlePassLevelFields
        }
      }
    }

    ${FRAGMENT_BATTLE_PASS_LEVEL_FILEDS}
  `,
  query: gql`
    query useGetBattlePass($input: BattlePassInput!) {
      getBattlePass(input: $input) {
        ...BattlePassFields
        getCategories {
          id
          getName
          getDescription
          color
          getCases {
            ...CaseFields
          }
        }
        getQuests {
          id
          quest {
            id
            getName
            type
            props
            typeDate
            getCoins
          }
          getBattlePassUserQuest {
            ...BattlePassUserQuestFields
          }
        }
        getProgress {
          id
          level
          nextLevel
          getNextLevel {
            ...BattlePassLevelFields
          }
        }
        getLevels {
          ...BattlePassLevelFields
        }
      }
    }

    ${FRAGMENT_BATTLE_PASS_FILEDS}
    ${FRAGMENT_CASE_FILEDS}
    ${FRAGMENT_BATTLE_PASS_LEVEL_FILEDS}
    ${FRAGMENT_BATTLE_PASS_USER_QUEST_FILEDS}
  `,
  subscription: gql`
    subscription updateBattlePass {
      updateBattlePass
    }
  `,
  fragments: {
    getBattlePass: FRAGMENT_BATTLE_PASS_FILEDS,
  },
};

interface Props {
  id?: number;
}

export const useGetBattlePass = ({ id }: Props) => {
  const { url } = useParams();
  let input = {};

  if (!id) {
    if (_.toInteger(url) < 1) {
      input = _.assign({}, input, {
        url,
      });
    } else {
      input = _.assign({}, input, {
        id: _.toInteger(url),
      });
    }
  } else {
    input = _.assign({}, input, {
      id,
    });
  }

  const query = useQuery<Data, Variables>(GET_BATTLE_PASS.query, {
    variables: {
      input,
    },
  });

  useSubscription(GET_BATTLE_PASS.subscription, {
    onData({ data }) {
      if (data.data) {
        query.refetch();
      }
    },
  });

  return query;
};
