import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, redirect, useNavigate } from 'react-router-dom';
import { useYaMetrica } from '~frontend/provider/yametrica';
import { useTimer } from '~components/hooks';
import { usePromo } from '~components/pay/PromoCode';

import { useModal } from './hooks';
import Modal from './Modal';
import Currency from '../Currency';

const CountDown = ({ endDate }: { endDate: string }) => {
  const timer = useTimer(endDate);

  return (
    <span>
      {timer?.hours}:{timer?.minutes}
      <span>:{timer?.seconds}</span>
    </span>
  );
};

export const WheelPrize: FunctionComponent = () => {
  const modalName = 'WheelPrize';
  const navigate = useNavigate();
  const { t } = useTranslation();
  const yametrica = useYaMetrica();
  const { onClose, isOpen, props } = useModal<{
    promoCode?: string;
    promoCodeTimeOut?: number;
    endDate?: string;
    image?: string;
    caseId?: number;
    caseName?: string;
    caseUrl?: string;
    isCasePrize?: boolean;
  }>(modalName);
  const [, , setPromoCode] = usePromo();

  const _onClose = () => {
    if (props?.isCasePrize === false) {
      setPromoCode(props?.promoCode, props?.promoCodeTimeOut);
    }

    onClose();
  };

  const onClick = () => {
    yametrica.reachGoal(`onClick:${modalName}`);

    _onClose();
  };

  const openCase = () => {
    window.open(`${props.caseUrl}`);
    onClose();
  };

  return (
    <>
      {props?.isCasePrize ? (
        <Modal
          show={isOpen}
          onClose={onClose}
          width={364}
          modalName={modalName}
        >
          <div className="box-modal modal-blue modal-blue-not-wheel">
            <div className="box-modal_close arcticmodal-close">
              <div className="icon material-icons" onClick={onClose}>
                close
              </div>
            </div>
            <div className="modal-blue-title">{t('SuperPrize_yours')}</div>
            <div className="modal-blue-title-sec">
              {t('You_get_case')} “{`${props.caseName}`}” <br />
              {t('Fool_free')}
            </div>
            <div className="modal-blue-case">
              <img src={props.image} />
            </div>
            <div className="modal-blue-btn-wrap">
              <button className="btn-yellow btn-price" onClick={openCase}>
                <span className="text">{t('Open case')}</span>
                <span className="cost">
                  <Currency /> 0
                </span>
              </button>
            </div>
            <div className="disclaimer">{t('Promo_time')}</div>
            {/* <div className="default-popup-wrap">
              <div className="balance-title">
                {t(`${modalName} balance-title`)}
              </div>
              <div
                className="box-modal_close arcticmodal-close"
                onClick={onClose}
              ></div>
              <div className="img">
                <img src={props?.image} alt="" />
              </div>
              <div className="promo-content">
                <div className="balance-btns">
                  <div className="popup-promo-disclaimer">
                    {t(`${modalName} popup-promo-disclaimer`)}
                  </div>
                  <Link
                    className="btn btn-pink btn-big"
                    onClick={onClick}
                    to="/pay"
                    state={{
                      promoCode: props?.promoCode,
                      promoCodeTimeOut: props?.promoCodeTimeOut,
                    }}
                  >
                    {t(`${modalName} btn`)}
                    <div className="timer">
                      (<CountDown endDate={props?.endDate} />)
                    </div>
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </Modal>
      ) : (
        <Modal
          show={isOpen}
          onClose={onClose}
          width={364}
          modalName={modalName}
        >
          <div className="box-modal default-popup popup-wheel-prize">
            <div className="default-popup-wrap">
              <div className="balance-title">
                {t(`${modalName} balance-title`)}
              </div>
              <div
                className="box-modal_close arcticmodal-close"
                onClick={onClose}
              ></div>
              <div className="img">
                <img src={props?.image} alt="" />
              </div>
              <div className="promo-content">
                <div className="balance-btns">
                  <div className="popup-promo-disclaimer">
                    {t(`${modalName} popup-promo-disclaimer`)}
                  </div>
                  <Link
                    className="btn btn-pink btn-big"
                    onClick={onClick}
                    to="/pay"
                    state={{
                      promoCode: props?.promoCode,
                      promoCodeTimeOut: props?.promoCodeTimeOut,
                    }}
                  >
                    {t(`${modalName} btn`)}
                    <div className="timer">
                      (<CountDown endDate={props?.endDate} />)
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
