import {
  FunctionComponent,
  SyntheticEvent,
  useCallback,
  useState,
  ChangeEvent,
} from 'react';
import _ from 'lodash';
import { Button } from '~ui/index';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { NavLink, useNavigate } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';

import { useToggle } from 'react-use';
import { useModal } from './hooks';
import { useConfig } from '../hooks';

import Slide from '../effects/Slide';
import Modal from './Modal';

const GET_BONUS = gql`
  mutation GetBonus($input: BonusInput!) {
    getBonus(input: $input) {
      bonusAmount
    }
  }
`;

interface BonusModalMutationData {
  getConfig: Config;
}

interface BonusModalMutationVariables {
  input: {
    bonusCode: string;
  };
}

const BonusModal: FunctionComponent = () => {
  const modalName = 'BonusModal';
  const navigate = useNavigate();
  const [isBonusHelpView, toggleBonusHelp] = useToggle(false);
  const [bonusCode, setBonusCode] = useState('');
  const getConfig = useConfig();
  const { t } = useTranslation();
  const { onClose, isOpen } = useModal(modalName);

  const [getBonus, { loading: loadingMutation }] = useMutation<
    BonusModalMutationData,
    BonusModalMutationVariables
  >(GET_BONUS, {
    variables: {
      input: {
        bonusCode,
      },
    },
  });

  const onGetBonus = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (loadingMutation) {
      event.preventDefault();
    }

    try {
      await getBonus();

      onClose();
    } catch (errors) {
      for (const error of errors.graphQLErrors) {
        if (error?.extensions?.promoCode) {
          navigate(`/pay`, {
            state: { promoCode: error?.extensions?.promoCode },
          });
        } else {
          toast.error(
            <>
              <div className="notify-title">{t('Error')}!</div>
              <div className="notify-text">{t(error.message)}</div>
            </>,
          );
        }
      }
    }
  };

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setBonusCode(event.target.value);
    },
    [setBonusCode],
  );

  const onToggleBonusHelp = useCallback(
    (event: SyntheticEvent) => {
      if (event) {
        event.preventDefault();
      }

      toggleBonusHelp();
    },
    [toggleBonusHelp],
  );

  return (
    <Modal show={isOpen} onClose={onClose} modalName={modalName}>
      <div className="box-modal-wrap" id="bonus-popup">
        <div className="box-modal">
          <div
            className="bonus-title"
            dangerouslySetInnerHTML={{
              __html: t('Enter <br />your bonus code'),
            }}
          />
          <div
            className="box-modal_close arcticmodal-close"
            onClick={onClose}
          />
          <div className="bonus-modal-code">
            <div className="label">{t('Your code')}:</div>
            <a href="#" className="bonus-link" onClick={onToggleBonusHelp}>
              {t('How to get it?')}
            </a>
            <div className="block-hidden-text">
              <Slide show={isBonusHelpView} height={80}>
                <div>
                  <div className="hidden-text">
                    {t('You can find it in our')}{' '}
                    <a
                      href={getConfig?.seo.vk}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      {t('VK group')}
                    </a>{' '}
                    {t(
                      'or ask referral code from someone who already registered',
                    )}
                  </div>
                </div>
              </Slide>
            </div>
            <div className="refer-input">
              <input
                type="text"
                placeholder={t('Enter code')}
                value={bonusCode}
                onChange={onChange}
              />
            </div>
            <Button
              loading={loadingMutation}
              className="btn-green"
              onClick={onGetBonus}
            >
              {t('Get bonus')}
            </Button>
            <NavLink
              to="/referral"
              onClick={onClose}
              className="ui button btn-yellow"
            >
              {t('Earn')}
            </NavLink>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BonusModal;
