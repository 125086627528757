import { useQuery, gql, DocumentNode, useSubscription } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Event as EventType, Profile as ProfileType } from '~types';
import _ from 'lodash';

const FRAGMENT_EVENT_FILEDS = gql`
  fragment EventFields on Event {
    id
    totalRank
    endDate
    getUrl
    props
    isLaunched
    isEndend
    startDate
    getPrizes {
      id
      color
      getImage
      getName
      isStatTrak
      quality
    }
    getLastRankUser {
      id
      spent
      ranking
      countOpen
    }
  }
`;

interface Types {
  query: DocumentNode;
  fragments?: {
    getEvent: DocumentNode;
  };
  subscription: DocumentNode;
}

interface Data {
  getEvent: EventType;
}

interface Variables {
  input: {
    id?: number;
    url?: string;
  };
}

export const GET_EVENT: Types = {
  query: gql`
    query useGetEvent($input: EventInput!) {
      getEvent(input: $input) {
        ...EventFields
      }
    }

    ${FRAGMENT_EVENT_FILEDS}
  `,
  subscription: gql`
    subscription updateEvent {
      updateEvent
    }
  `,
  fragments: {
    getEvent: FRAGMENT_EVENT_FILEDS,
  },
};

interface Props {
  id?: number;
}

export const useGetEvent = ({ id }: Props) => {
  const { url } = useParams();
  let input = {};

  if (!id) {
    if (_.toInteger(url) < 1) {
      input = _.assign({}, input, {
        url,
      });
    } else {
      input = _.assign({}, input, {
        id: _.toInteger(url),
      });
    }
  } else {
    input = _.assign({}, input, {
      id,
    });
  }

  const query = useQuery<Data, Variables>(GET_EVENT.query, {
    variables: {
      input,
    },
  });

  useSubscription(GET_EVENT.subscription, {
    onData({ data }) {
      if (data.data) {
        query.refetch();
      }
    },
  });

  return query;
};
