import {
  useState,
  useRef,
  memo,
  useEffect,
  useMemo,
  SyntheticEvent,
  ChangeEvent,
  FunctionComponent,
} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Search as SearchType } from '~types';
import { useQuery, gql } from '@apollo/client';
import { Input } from '~ui/index';
import { useClickAway, useToggle } from 'react-use';
import { useYaMetrica } from '~frontend/provider/yametrica';

interface DropWrapData {
  getSearch: SearchType;
}

interface DropWrapVars {
  input: {
    value: string;
  };
}

interface IDropWrapProps {
  value: string;
  toggle: boolean;
  setToggle: (nextValue?: any) => void;
}

const DropWrap: FunctionComponent<IDropWrapProps> = memo(
  ({ value, toggle, setToggle }) => {
    const ref = useRef(null);
    const {
      t,
      i18n: { language },
    } = useTranslation();

    useClickAway(ref, () => {
      setToggle(false);
    });

    const { loading, error, data } = useQuery<DropWrapData, DropWrapVars>(
      gql`
        query Search($input: SearchInput!) {
          getSearch(input: $input) {
            getCases {
              id
              getName
              getUrl
            }
            getItems {
              id
              market_hash_name
              name_en
              market_name
              category
            }
          }
        }
      `,
      {
        variables: {
          input: {
            value,
          },
        },
      },
    );

    const getCases = useMemo(
      () => data?.getSearch?.getCases || [],
      [data?.getSearch?.getCases],
    );
    const getItems = useMemo(
      () => data?.getSearch?.getItems || [],
      [data?.getSearch?.getItems],
    );

    useEffect(() => {
      if (getCases.length > 0 || getItems.length > 0) {
        setToggle(true);
      } else {
        setToggle(false);
      }
    }, [getCases, getItems, setToggle]);

    const onClick = () => {
      setToggle(false);
    };

    if (loading) {
      return null;
    } else if (error) {
      return <>Search | Error! {error.message}</>;
    }

    if (!toggle) {
      return null;
    }

    return (
      <div
        className="header-search-drop-wrap"
        ref={ref}
        style={{ opacity: 1, pointerEvents: 'all' }}
      >
        <div className="header-search-drop">
          {getCases.length > 0 && (
            <div className="header-search-drop-cat">
              <div className="title">{t('Cases')}</div>
              {getCases.map(({ getUrl, getName, id }) => (
                <Link
                  className="header-search-drop-cat-item"
                  to={getUrl}
                  onClick={onClick}
                  key={id}
                >
                  {getName}
                </Link>
              ))}
            </div>
          )}
          {getItems.length > 0 && (
            <div className="header-search-drop-cat">
              <div className="title">{t('Skins')}</div>
              {getItems.map(getItem => (
                <Link
                  className="header-search-drop-cat-item"
                  to={`/search/${getItem.id}`}
                  key={getItem.id}
                  onClick={onClick}
                >
                  {language === 'ru'
                    ? getItem.market_name
                    : getItem.market_hash_name}
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  },
);

DropWrap.displayName = 'DropWrap';

export default function Search() {
  const yametrica = useYaMetrica();

  const [value, setSearch] = useState('');
  const [toggle, setToggle] = useToggle(false);
  const { t } = useTranslation();

  const reachGoal = (event: SyntheticEvent<HTMLElement>) => {
    yametrica.reachGoal(event?.currentTarget.getAttribute('data-reachgoal'));
  };

  const clearSearch = (event: SyntheticEvent<HTMLElement>) => {
    event.preventDefault();

    reachGoal(event);

    if (value.length > 0) {
      setToggle(false);
      setSearch('');
    } else {
      setToggle(true);
    }
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setToggle(true);
    setSearch(event.currentTarget.value);
  };

  return (
    <div className="header-search">
      <div className="header-search__wrap">
        <Input
          type="text"
          placeholder={t('Search')}
          value={value}
          onChange={onChange}
        />
        <a
          className={`header-search__btn ${value.length > 0 && 'active'}`}
          onClick={clearSearch} 
          href="#"
          data-reachgoal="search_click"
        >
          <div className="icon material-icons" data-icon="&#xe8b6;"></div>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 8 8"
            style={{ marginBottom: 6 }}
          >
            <path
              d="M2210,75l-3,3-1-1,3-3-3-3,1-1,3,3,3-3,1,1-3,3,3,3-1,1Z"
              transform="translate(-2206 -70)"
              fill="#d32ce6"
            ></path>
          </svg> */}
        </a>
      </div>
      {value.length > 2 && (
        <DropWrap value={value} toggle={toggle} setToggle={setToggle} />
      )}
    </div>
  );
}
