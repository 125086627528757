import { useQuery, gql } from '@apollo/client';
import { Game as GameType } from '~types';
import { useParams } from 'react-router-dom';
import { FRAGMENT_GAME_FILEDS } from '~components/header/live';
import _ from 'lodash';

interface GetGetLiveGamesInCaseData {
  getLiveGamesInCase: GameType[];
}

export const GET_LIVE_GAMES_IN_CASE = gql`
  query useGetLiveGamesInCase($input: CaseInput!) {
    getLiveGamesInCase(input: $input) {
      ...LiveGameFields
    }
  }

  ${FRAGMENT_GAME_FILEDS}
`;

export default () => {
  const { url } = useParams();
  let input = {};

  if (_.toInteger(url) < 1) {
    input = _.assign({}, input, {
      url,
    });
  } else {
    input = _.assign({}, input, {
      id: _.toInteger(url),
    });
  }

  const { data } = useQuery<GetGetLiveGamesInCaseData>(GET_LIVE_GAMES_IN_CASE, {
    variables: {
      input,
    },
  });

  return data?.getLiveGamesInCase;
};
