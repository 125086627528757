import {
  useQuery,
  gql,
  useSubscription,
  QueryHookOptions,
} from '@apollo/client';
import { useMemo } from 'react';
import { Profile as ProfileType, Event as EventTypes } from '~types';
import _ from 'lodash';

interface Data {
  getProfile: ProfileType;
}

interface Variables {
  eventId: number;
}

const FRAGMENT_EVENT_PROGRESS_FILEDS = gql`
  fragment EventProgressFields on EventProgress {
    nextRank
    spent
    countOpen
    getItem {
      id
      color
      getImage
      getName
    }
    getNextItem {
      id
      color
      getImage
      getName
    }
    ranking
    nextPrizeSpent
  }
`;

export const GET_PROFILE_EVENT_PROGRESS = {
  query: gql`
    query useGetProfileEventProgress($eventId: Int!) {
      getProfile {
        id
        userName
        isPlayEvent
        getEventProgress(eventId: $eventId) {
          ...EventProgressFields
        }
      }
    }

    ${FRAGMENT_EVENT_PROGRESS_FILEDS}
  `,
  subscription: gql`
    subscription updateProfileEventProgress($eventId: Int!) {
      updateProfileEventProgress(eventId: $eventId) {
        id
        getEventProgress(eventId: $eventId) {
          ...EventProgressFields
        }
      }
    }

    ${FRAGMENT_EVENT_PROGRESS_FILEDS}
  `,
};

export const useGetProfileEventProgress = (
  eventId: number,
  options?: QueryHookOptions<Data, Variables>,
) => {
  useSubscription(GET_PROFILE_EVENT_PROGRESS.subscription, {
    variables: {
      eventId,
    },
  });

  return useQuery<Data, Variables>(GET_PROFILE_EVENT_PROGRESS.query, {
    variables: {
      eventId,
    },
    ...options,
  });
};
