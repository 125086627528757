import { FunctionComponent } from 'react';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';

import { useModal } from './hooks';
import Modal from './Modal';

import { useGetDistributionGame, useConfig } from '~components/hooks';

import { Loader } from '../Loader';
import { GetName, SteamItemImage } from '../item';
import Currency from '../Currency';
import { Price } from '~ui/Price';
import { DistributionGamesHistory } from '../distributionModal';
import { Link } from 'react-router-dom';

interface Props {
  id: number;
  onClose: () => void;
}

const DistributionWinModalWrap: FunctionComponent<Props> = ({
  id,
  onClose,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const getConfig = useConfig();
  const { loading, data, error } = useGetDistributionGame(id);

  const getDistributionGame = data?.getDistributionGame;

  if (loading) {
    return <Loader />;
  } else if (error) {
    return <>DistributionModal | Error! {error.message}</>;
  }

  const getItem = getDistributionGame?.getItem;

  return (
    <div className="box-modal-wrap" data-modal="DistributionWinModal">
      <div className="box-modal modal-blue popup-demo-free popup-demo-free-win">
        <div className="box-modal_close arcticmodal-close" onClick={onClose}>
          <div className="icon material-icons">close</div>
        </div>
        <div className="popup-demo-free-user">
          <div className="popup-demo-free-title">Public Hash</div>
          <div className="popup-demo-free-hash">
            {getDistributionGame?.publicHash}
          </div>
          {getDistributionGame?.distributionNumber && (
            <>
              <div className="popup-demo-free-title">Random Number</div>
              <div className="popup-demo-free-hash">
                {getDistributionGame?.distributionNumber}
              </div>
              <div className="popup-demo-free-title">Win ticket</div>
              <div className="popup-demo-free-hash">
                {getDistributionGame?.distributionNumber} *{' '}
                {getDistributionGame?.usersCount} ={' '}
                {_.ceil(
                  getDistributionGame?.distributionNumber *
                    getDistributionGame?.usersCount,
                )}
              </div>
            </>
          )}
          <DistributionGamesHistory
            distributionId={getDistributionGame?.distributionId}
          />
        </div>

        <div className="popup-demo-free-prize">
          <div className="popup-demo-free-title">
            {t(getDistributionGame.getType)} #{getDistributionGame?.id}
          </div>
          <div className="popup-demo-free-prize-title">
            {t('DistributionModal popup-demo-free-prize-title')}
          </div>
          <div className="page-timer">
            <div className="page-timer-wrap">
              <div className="page-timer-close-text">
                <span className="emoji">🎉</span>
                <span className="timer-title">
                  {t('DistributionModal page-timer timer-title')}
                </span>
                <span className="emoji">🎉</span>
              </div>
            </div>
          </div>
          <Link
            className="popup-demo-user-link"
            to={`/user/${getDistributionGame?.getWinner?.id}`}
          >
            <div className="popup-demo-user-link-ava">
              <img src={getDistributionGame?.getWinner?.avatar} />
            </div>
            <div className="popup-demo-user-link-name">
              {getDistributionGame?.getWinner?.userName}
            </div>
            <div className="popup-demo-user-link-btn">
              <div className="icon material-icons">north_east</div>
              <div className="tooltip-block">{t('To the profile')}</div>
            </div>
          </Link>
          <div className="popup-demo-free-prize-text">
            {_.get(
              getDistributionGame?.getDistribution,
              `description[${language}]`,
            )}
          </div>
          <div className="in-case-item active status-4" data-rar={getItem?.color}>
            <div className="in-case-cost">
              {getConfig.isEU ? (
                <>
                  <Currency />
                  <Price sum={getDistributionGame?.price} />
                </>
              ) : (
                <>
                  <Price sum={getDistributionGame?.price} />
                  <Currency />
                </>
              )}
            </div>
            <div className="in-case-item-wrap">
              <div className="img">
                <div className="in-case-item-bg" />
                <SteamItemImage url={getItem?.getImage} size="350x350" />
              </div>
              <div className="in-case-info">
                <div className="in-case-item-name">
                  <GetName getItem={getItem} type={0} />
                </div>
                <div className="in-case-item-desc">
                  <GetName getItem={getItem} type={1} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DistributionWinModal: FunctionComponent = () => {
  const modalName = 'DistributionWinModal';

  const {
    onClose,
    isOpen,
    props: { id },
  } = useModal<{ id: number }>(modalName);

  return (
    <Modal show={isOpen} onClose={onClose} modalName={modalName} width={824}>
      <DistributionWinModalWrap onClose={onClose} id={id} />
    </Modal>
  );
};
