import { FunctionComponent, memo } from 'react';

interface GetTypeSocialProps {
  userId: string;
  userName?: string;
  social: number;
}

export const GetTypeSocial: FunctionComponent<GetTypeSocialProps> = memo(
  ({ userId, social, userName }: GetTypeSocialProps) => {
    return social === 1 ? (
      <a
        href={`//vk.com/id${userId}`}
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        VK
      </a>
    ) : social === 2 ? (
      <a
        href={`//facebook.com/profile.php?id=${userId}`}
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        Facebook
      </a>
    ) : social === 3 ? (
      <a href={`#`}>Google</a>
    ) : social === 4 ? (
      <a
        href={`//twitch.tv/${userName}`}
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        Twitch
      </a>
    ) : social === 5 ? (
      <a
        href={`//discord.com/channels/@me/${userId}`}
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        Discord
      </a>
    ) : social === 6 ? (
      <a
        href={`tg://user?id=${userId}`}
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        Telegram
      </a>
    ) : (
      <a
        href={`//steamcommunity.com/profiles/${userId}`}
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        Steam
      </a>
    );
  },
);
