import { FunctionComponent, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useYaMetrica } from '~frontend/provider/yametrica';
import { useModal } from '../hooks';
import { useGetCase, useGetActiveModal } from '~components/hooks';
import { Loader } from '~components/Loader';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';

const ModalView = ({ props, onClose, modalName }) => {
  const { t } = useTranslation();
  const yametrica = useYaMetrica();
  const { refetch } = useGetActiveModal();

  const { loading, data } = useGetCase({ id: props?.caseId });
  const getCase = data?.getCase;

  useEffect(() => {
    refetch();
  }, [props?.caseId]);

  const onClick = () => {
    yametrica.reachGoal(`onClick:${modalName}`);

    onClose();
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div className="box-modal_close arcticmodal-close" onClick={onClose}>
        <div className="icon material-icons">close</div>
      </div>
      <div
        className="modal-blue-title"
        dangerouslySetInnerHTML={{
          __html: t(`${modalName} modal-blue-title`),
        }}
      ></div>
      <div className="modal-blue-case">
        <img src={getCase?.getImage} alt={getCase?.getName} />
      </div>
      <div className="modal-blue-text revers">
        <div className="modal-blue-text-num">-{props?.discount}%</div>
        <div
          className="modal-blue-text-name"
          dangerouslySetInnerHTML={{
            __html: t(`${modalName} modal-blue-text-name`),
          }}
        ></div>
      </div>
      <div className="modal-blue-btn-wrap" onClick={onClick}>
        <Link onClick={onClick} to={getCase?.getUrl}>
          <button className="btn-yellow">
            {t(`${modalName} modal-blue-btn-wrap`)}
          </button>
        </Link>
      </div>
    </>
  );
};

export const PromoModal13: FunctionComponent = () => {
  const modalName = 'PromoModal13';

  const { onClose, isOpen, props } = useModal(modalName);

  const _onClose = () => {
    onClose();
  };

  return (
    <Modal show={isOpen} onClose={_onClose} width={412} modalName={modalName}>
      <div className="box-modal modal-blue">
        <ModalView props={props} onClose={_onClose} modalName={modalName} />
      </div>
    </Modal>
  );
};
