import _ from 'lodash';
import {
  UserController,
  ProfileController,
  Promo,
  Game,
  Faq,
  Currency,
  Case,
  EventUser,
  Item,
  BattlePassLevel,
} from './controller';
import { InMemoryCacheConfig } from '@apollo/client';
import { HistoryInput } from '~types';

const merge = (
  existing: any[],
  incoming: any[],
  { readField, mergeObjects },
) => {
  const merged: any[] = existing ? existing.slice(0) : [];
  const idToIndex: Record<string, number> = Object.create(null);

  if (existing) {
    existing.forEach((item, index) => {
      idToIndex[readField('id', item)] = index;
    });
  }
  incoming.forEach(item => {
    const id = readField('id', item);
    const index = idToIndex[id];
    if (typeof index === 'number') {
      // Merge the new author data with the existing author data.
      merged[index] = mergeObjects(merged[index], item);
    } else {
      // First time we've seen this author in this array.
      idToIndex[id] = merged.length;
      merged.push(item);
    }
  });

  return merged;
};

export const cacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    Categories: {
      fields: {
        getCategories: {
          merge,
        },
      },
    },
    BattlePassLevel: {
      fields: BattlePassLevel,
    },
    Currency: {
      fields: Currency,
    },
    Faq: {
      fields: Faq,
    },
    Promo: {
      fields: Promo,
    },
    Item: { fields: Item },
    User: {
      fields: UserController,
    },
    Profile: {
      fields: ProfileController,
    },
    EventUser: {
      fields: EventUser,
    },
    Game: {
      fields: Game,
    },
    Case: {
      fields: Case,
    },
    UpgradeItems: {
      fields: {
        getItems: {
          merge,
        },
      },
    },
    ActiveGames: {
      fields: {
        getGames: {
          merge,
        },
      },
    },
    EventUsers: {
      fields: {
        getEventUsers: {
          merge,
        },
      },
    },
    Query: {
      fields: {
        getUpgradeItems: {
          keyArgs: (args: any) => {
            const input = args.input;
            return [
              input.filterSearch,
              input.filterOrder.sortField,
              input.filterOrder.sortOrder,
            ].join(',');
          },
        },
        getHistory: {
          keyArgs: (args: any) => {
            return `${String(args.input.userId)}:${args.input.isAwait}`;
          },
          merge(existing: any, incoming: any, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        posts: {
          keyArgs: ['orderBy', 'where', 'take', 'skip'],
        },
        getStatistics: {
          merge(existing: any, incoming: any, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        getConfig: {
          merge(existing: any, incoming: any, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        getStats: {
          merge(existing: any, incoming: any, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        getCategories: {
          keyArgs: [],
          merge: true,
        },
        getActiveHistory: {
          keyArgs: (args: { input: HistoryInput }) => {
            return `${String(args.input.userId)}:${args.input.price}:${
              args.input.minPrice
            }:${args.input.filterOrder.sortField}:${
              args.input.filterOrder.sortOrder
            }:${args.input.maxPrice}:${args.input.filterSearch}:${
              args.input.limit
            }`;
          },
          read(read, { readField }) {
            if (read) {
              const { count = 0, getGames = [] } = read;
              const _getGames = _.filter(
                getGames,
                item => readField('status', item) === 1,
              );

              const minus = _.size(getGames) - _.size(_getGames);

              return {
                count: count - minus,
                getGames: _getGames,
              };
            }

            return read;
          },
        },
      },
    },
  },
};
