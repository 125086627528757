import { FunctionComponent } from 'react';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';

import { useModal } from './hooks';
import Modal from './Modal';

import { useGetDistributionGame, useConfig } from '~components/hooks';

import { Loader } from '../Loader';
import { GetName, SteamItemImage } from '../item';
import Currency from '../Currency';
import { Price } from '~ui/Price';
import { Link } from 'react-router-dom';
import GameChange from '../game/GameChange';
import { useGetGame } from '../game/hooks';

interface Props {
  id: number;
  gameId: number;
  onClose: () => void;
}

const DistributionUserWinModalWrap: FunctionComponent<Props> = ({
  id,
  gameId,
  onClose,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { loading, data, error } = useGetDistributionGame(id);
  const getGame = useGetGame(gameId);
  const getItem = getGame?.getItem;
  const getConfig = useConfig();

  const getDistributionGame = data?.getDistributionGame;

  if (loading && !getGame) {
    return <Loader />;
  } else if (error) {
    return <>DistributionUserWinModalWrap | Error! {error.message}</>;
  }

  return (
    <div className="box-modal-wrap" data-modal="DistributionUserWinModal">
      <div className="box-modal modal-blue popup-demo-free popup-demo-free-win">
        <div className="box-modal_close arcticmodal-close" onClick={onClose}>
          <div className="icon material-icons">close</div>
        </div>
        <div className="popup-demo-free-prize">
          <div className="popup-demo-free-title">
            {t(getDistributionGame?.getType)} #{getDistributionGame?.id}
          </div>
          <div className="popup-demo-free-prize-title">
            {t('DistributionModal popup-demo-free-prize-title')}
          </div>
          <div className="page-timer">
            <div className="page-timer-wrap">
              <div className="page-timer-close-text">
                <span className="emoji">🎉</span>
                <span className="timer-title">{t('WINNING')}</span>
                <span className="emoji">🎉</span>
              </div>
            </div>
          </div>
          <div className="popup-demo-free-prize-text">
            {_.get(
              getDistributionGame?.getDistribution,
              `description[${language}]`,
            )}
          </div>
          <div className="in-case-item active status-4" data-rar={getItem?.color}>
            <div className="in-case-cost">
              {getConfig.isEU ? (
                <>
                  <Currency />
                  <Price sum={getDistributionGame?.price} />
                </>
              ) : (
                <>
                  <Price sum={getDistributionGame?.price} />
                  <Currency />
                </>
              )}
            </div>
            <div className="in-case-item-wrap">
              <div className="img">
                <div className="in-case-item-bg" />
                <SteamItemImage url={getItem?.getImage} size="350x350" />
              </div>
              <div className="in-case-info">
                <div className="in-case-item-name">
                  <GetName getItem={getItem} type={0} />
                </div>
                <div className="in-case-item-desc">
                  <GetName getItem={getItem} type={1} />
                </div>
              </div>
            </div>
          </div>
          {getGame?.status === 1 && (
            <GameChange className="btn btn-sell" getGame={getGame} type={0}>
              {getGame?.status === 1 && (
                <div className="btn-sale-cost-wrap">
                  <div className="btn-sale-cost">
                    {getConfig.isEU ? (
                      <>
                        <Currency />
                        <Price sum={getGame?.price} />
                      </>
                    ) : (
                      <>
                        <Price sum={getGame?.price} />
                        <Currency />
                      </>
                    )}
                  </div>

                  <div className="btn-sale-cost percent">+3%</div>
                </div>
              )}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="42"
                viewBox="0 0 42 42"
              >
                <path
                  d="M1992,1385.667l16-16h-8V1367h13.333v13.333h-2.667v-8l-16,16Z"
                  transform="translate(-1981.333 -1356.333)"
                  fill="#fff"
                ></path>
              </svg>
              <span className="text">
                {getGame?.status === 1 ? t('Sell for') : t('Sold for')}
              </span>
              <div className="cost">
                {getConfig.isEU ? (
                  <>
                    <Currency />
                    <Price sum={getGame?.priceSell} />
                  </>
                ) : (
                  <>
                    <Price sum={getGame?.priceSell} />
                    <Currency />
                  </>
                )}
              </div>
            </GameChange>
          )}
          <div className="btns-more">
            {/*  <a className="btn-back" href="#">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="42"
                  height="42"
                  viewBox="0 0 42 42"
                >
                  <rect
                    width="42"
                    height="42"
                    transform="translate(0.333 0.333)"
                    fill="#4b69ff"
                    opacity="0"
                  ></rect>
                  <path
                    d="M-1.133-13.7v-5l5,7-5,6v-5c-8.993-.108-12.951-5.877-13-12a13.062,13.062,0,0,1,2-7l2,3a7.438,7.438,0,0,0-1,4c0,2.572,1.18,4.18,3,6A10.188,10.188,0,0,0-1.133-13.7Zm0-22c7.826-.231,12,6.07,12,13a9.913,9.913,0,0,1-2,6l-2-3c.728-1.31,1-1.4,1-3a10.188,10.188,0,0,0-3-7c-1.82-1.82-3.428-3-6-3v5l-6-7,6-6Z"
                    transform="translate(19.7 -1.863) rotate(180)"
                    fill="#4b69ff"
                  ></path>
                </svg>
              </div>
              <span>К кейсу</span>
            </a>
            */}
            {getGame?.status === 1 && (
              <>
                <GameChange
                  className="btn-withdraw"
                  type={3}
                  onDone={onClose}
                  getGame={getGame}
                >
                  <div className="icon material-icons">call_received</div>
                  <span>{t('Withdraw')}</span>
                </GameChange>
                <Link
                  className="btn-back btn-contracts"
                  to={{ pathname: '/contract' }}
                  state={{ gameIds: [getGame?.id] }}
                >
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                    >
                      <path
                        d="M17.334,32H14.667V29.334A13.162,13.162,0,0,1,6.4,25.6a13.157,13.157,0,0,1-3.737-8.264H0V14.667H2.667A13.16,13.16,0,0,1,6.4,6.4a13.167,13.167,0,0,1,8.262-3.737V0h2.667V2.666A13.167,13.167,0,0,1,25.6,6.4a13.158,13.158,0,0,1,3.736,8.264H32v2.666H29.333A13.162,13.162,0,0,1,25.6,25.6a13.167,13.167,0,0,1-8.264,3.738V32Zm0-6.666v1.332a10.011,10.011,0,0,0,9.334-9.334H25.334V14.667h1.334a10.013,10.013,0,0,0-9.334-9.333V6.666H14.667V5.333a10.009,10.009,0,0,0-9.332,9.333H6.667v2.666H5.337a10,10,0,0,0,9.329,9.334V25.336ZM16,22.667A6.93,6.93,0,0,1,9.334,16,6.93,6.93,0,0,1,16,9.334,6.93,6.93,0,0,1,22.667,16,6.93,6.93,0,0,1,16,22.667ZM16,12a4,4,0,1,0,4,4A4,4,0,0,0,16,12Z"
                        transform="translate(5.333 5.333)"
                        fill="#fff"
                        stroke="rgba(0,0,0,0)"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                      ></path>
                    </svg>
                  </div>
                  <span>{t('Add to the contract')}</span>
                </Link>
              </>
            )}
            <Link className="btn-back btn-home" to="/profile">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="42"
                  height="42"
                  viewBox="0 0 42 42"
                >
                  <g transform="translate(42.333 42.333) rotate(180)">
                    <path
                      d="M16.5-23.5h6a2.557,2.557,0,0,0-1-2,2.63,2.63,0,0,0-2-1,2.63,2.63,0,0,0-2,1A2.557,2.557,0,0,0,16.5-23.5Zm14-1,5,4h-5v14h-8v-10h-6v10h-8v-14h-5l16-14,6,5v-4h5Z"
                      transform="translate(40.333 1.333) rotate(180)"
                      fill="rgba(75,105,255,0.5)"
                    ></path>
                  </g>
                </svg>
              </div>
              <span>{t('Inventory')}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DistributionUserWinModal: FunctionComponent = () => {
  const modalName = 'DistributionUserWinModal';

  const {
    onClose,
    isOpen,
    props: { id, gameId },
  } = useModal<{ id: number; gameId: number }>(modalName);

  return (
    <Modal show={isOpen} onClose={onClose} modalName={modalName} width={412}>
      <DistributionUserWinModalWrap onClose={onClose} id={id} gameId={gameId} />
    </Modal>
  );
};
