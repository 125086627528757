import { useCallback, SyntheticEvent, FunctionComponent } from 'react';
import { useCookies } from 'react-cookie';

import _ from 'lodash';

export const SiteSound: FunctionComponent = () => {
  const [cookies, setCookie] = useCookies(['disableSound']);

  const onClick = useCallback(
    (event: SyntheticEvent<HTMLDivElement>) => {
      event.preventDefault();

      const expires = new Date();
      expires.setFullYear(expires.getFullYear() + 1);
      setCookie('disableSound', !(cookies.disableSound === 'true'), {
        expires: expires,
        path: '/',
      });

      if (document.querySelector('audio')) {
        if (cookies?.disableSound === 'true') {
          document.querySelector('audio').volume = 0.2;
          document.querySelector('audio').play();
        } else {
          document.querySelector('audio').pause();
        }
      }
    },
    [cookies],
  );

  return (
    <div
      className={`header__controls-sound ${
        cookies.disableSound === 'true' && 'active'
      }`}
      onClick={onClick}
    >
      <div className="icon material-icons" data-icon="&#xe050;"></div>
    </div>
  );
};
