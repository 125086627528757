import { useQuery, gql } from '@apollo/client';

import _ from 'lodash';

export interface Post {
  id: number;
  title: string;
  publishDate: Date;
  description: string;
  background: {
    id: number;
    url: string;
  };
  tags: {
    id: number;
    name: string;
  }[];
  content: {
    document: any;
  };
  status: string;
  author: {
    id: number;
    name: string;
    avatar: {
      id: number;
      url: string;
    };
  };
}

export const PROPS = `
id
title
publishDate
description
status
background {
  id
  url
}
tags{
  id
  name
}
content {
  document(hydrateRelationships: true)
}
author {
  id
  name
  avatar {
    id
    url
  }
}`;

export const GET_POST = (label = 'Post') => gql`
  query ${label}(
    $where: PostWhereUniqueInput!
  ) @api(name: Blog) {
    post(where: $where) {
      ${PROPS}
    }
  }
`;

export interface GetData {
  post: Post;
}

export const useGetPost = (variables: any, label?: string) => {
  return useQuery<GetData>(GET_POST(label), {
    variables,
  });
};
