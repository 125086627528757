import { useQuery, gql } from '@apollo/client';
import { PROPS, Post } from './useGetPost';
import _ from 'lodash';

export const GET_NEWS = (label = 'News') => gql`
  query ${label}(
    $where: PostWhereInput!
    $orderBy: [PostOrderByInput!]!
    $take: Int
    $skip: Int!
  ) @api(name: Blog) {
    posts(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      ${PROPS}
    }
  }
`;

export const GET_NEWS_COUNT = (label = 'NewsCount') => gql`
  query ${label}(
    $where: PostWhereInput! = {}
  ) @api(name: Blog) {
      postsCount(where: $where)
  }
`;

export interface GetData {
  posts: [Post];
}

export interface GetNewsCountData {
  postsCount: number;
}

export const useGetNews = (variables: any, label?: string) => {
  return useQuery<GetData>(GET_NEWS(label), {
    variables: {
      skip: 0,
      where: {},
      orderBy: [],
      ...variables,
    },
    notifyOnNetworkStatusChange: true,
  });
};

export const useGetNewsCount = (variables: any, label?: string) => {
  return useQuery<GetNewsCountData>(GET_NEWS_COUNT(label), {
    variables,
  });
};
