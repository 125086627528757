import {
  useCallback,
  memo,
  MouseEventHandler,
  SyntheticEvent,
  FunctionComponent,
} from 'react';
import { useConfig } from '../hooks/useConfig';
import { useCookies } from 'react-cookie';

import { LazyLoadImage } from 'react-lazy-load-image-component';

interface CurrencyProps {
  key: string;
  currency: string;
  setCurrency: MouseEventHandler<HTMLDivElement>;
  activeCurrency: string;
}

const Currency: FunctionComponent<CurrencyProps> = memo(
  ({ currency, setCurrency, activeCurrency }) => (
    <div
      data-currency={currency}
      className={`currency-item ${currency === activeCurrency && 'active'}`}
      onClick={setCurrency}
    >
      <span>{currency}</span>
    </div>
  ),
);

Currency.displayName = 'Currency';

export const Currencies: FunctionComponent = () => {
  const [cookies, setCookie] = useCookies(['currency']);
  const getConfig = useConfig();

  const setCurrency = useCallback(
    (
      event: SyntheticEvent<HTMLDivElement>,
      { currency } = event.currentTarget.dataset,
    ) => {
      const expires = new Date();
      expires.setDate(expires.getDate() + 14);
      setCookie('currency', currency, { expires: expires, path: '/' });
    },
    [setCookie],
  );

  const activeCurrency = cookies.currency || getConfig?.getCurrency.symbol;

  return (
    <div className="currency">
      <div className="currency-item active">
        <span className="currency-name">{activeCurrency}</span>
        <span className="material-icons">arrow_drop_down</span>
      </div>
      <div className="currency-drop">
        <div className="currency-drop-wrap">
          {['$', '€', '₽'].map(currency => (
            <Currency
              key={currency}
              setCurrency={setCurrency}
              currency={currency}
              activeCurrency={activeCurrency}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
