import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { useYaMetrica } from '~frontend/provider/yametrica';

import { useSharedState } from '../';

export const modalList = {};

interface OnShowProps {
  props?: object;
  closeModal?: string;
  reachgoal?: string;
}

export function useOpenModal() {
  const [value, setValue] = useSharedState();
  const navigate = useNavigate();
  const yametrica = useYaMetrica();

  return {
    isActiveModal: !!_.findKey(value, { isOpen: true }),
    onCloseAll: () => {
      return setValue({
        type: 'onCloseAll',
      });
    },
    onClose: (modalName: string) => {
      return setValue({
        type: 'onClose',
        modalName,
      });
    },
    onShow: (modalName: string, props?: any) => {
      if (_.isFunction(props?.onInit)) {
        props.onInit();
      }

      yametrica.reachGoal(_.get(props, 'reachgoal', modalName));

      if (modalName === 'FundsModal') {
        return navigate('/pay', { state: { promoCode: props?.promoCode } });
      }

      return setValue({
        type: 'onShow',
        modalName,
        props,
      });
    },
    getProps: (modalName: string) => _.get(value, modalName, {}),
    updateProps: (modalName: string, props?: any) => {
      return setValue({
        type: 'updateProps',
        modalName,
        props,
      });
    },
    isOpen: (modalName: string) => value[modalName]?.isOpen,
  };
}

export function useModal<Props>(modalName: string): {
  modalName: string;
  onClose: () => void;
  onShow: (props?: OnShowProps) => void;
  isOpen: boolean;
  props: Props;
  updateProps: (props?: Props) => void;
} {
  const { onShow, getProps, onClose, isOpen, updateProps } = useOpenModal();

  return {
    modalName,
    onClose: useCallback(() => onClose(modalName), [modalName]),
    onShow: useCallback(
      (props?: OnShowProps) => {
        onShow(modalName, props);
      },
      [modalName],
    ),
    isOpen: isOpen(modalName),
    props: getProps(modalName),
    updateProps: useCallback(
      (props?: any) => updateProps(modalName, props),
      [modalName],
    ),
  };
}
