import { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { useWindowScroll } from 'react-use';

export const UpButton: FunctionComponent = () => {
  const [active, setActive] = useState(false);
  const { t } = useTranslation();
  const { y } = useWindowScroll();

  useEffect(() => {
    if (y > 1000) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [y]);

  const scrollTop = (event: SyntheticEvent) => {
    event.preventDefault();

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    setActive(false);
  };

  return (
    <button className={`scroll-page ${active && 'active'}`} onClick={scrollTop}>
      <div className="tooltip-block">{t('Up')}</div>
    </button>
  );
};
