import { useQuery, gql, QueryOptions, QueryHookOptions } from '@apollo/client';
import { Case as CaseType, GetCasesInput } from '~types';
import { GET_CASE } from './useGetCase';

export const GET_CASES = gql`
  query useGetCases($input: GetCasesInput!) {
    getCases(input: $input) {
      ...CaseFields
    }
  }

  ${GET_CASE.fragments.getCase}
`;

interface Data {
  getCases: CaseType[];
}

export const useGetCases = (
  input?: GetCasesInput,
  options?: QueryHookOptions,
) => {
  return useQuery<Data>(GET_CASES, { ...options, variables: { input } });
};
