import { FunctionComponent, memo, useState } from 'react';
import { useModal } from './hooks';
import Modal from './Modal';
import { useTranslation } from 'react-i18next';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import { Loader } from '../Loader';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from 'apollo-server-core';
import { GET_PROFILE, useConfig, useProfile } from '../hooks';
import { toast } from 'react-toastify';

interface PhoneNumberModalProps {
  id: number;
  onClose: any;
}

const phoneMasks: { [key: string]: string } = {
  VN: '+84 ## ### ####',
  UZ: '+998 ## ### ####',
  UY: '+598 # ### ## ##',
  US: '+1 ### ### ####',
  GB: '+44 ## #### ####',
  AE: '+971 # ### ####',
  TR: '+90 ### ### ## ##',
  TH: '+66 ## ### ####',
  TJ: '+992 ## ### ####',
  CH: '+41 ## ### ## ##',
  SE: '+46 ## ### ## ##',
  ES: '+34 ### ### ###',
  SI: '+386 ## ### ###',
  SK: '+421 ### ### ###',
  SA: '+966 # ### ####',
  RO: '+40 ## ### ####',
  PT: '+351 ### ### ###',
  PL: '+48 ### ### ###',
  PH: '+63 ## ### ####',
  PE: '+51 ## ### ####',
  NO: '+47 ### ## ###',
  NL: '+31 ## ### ####',
  MD: '+373 ### ######',
  MX: '+52 ## #### ####',
  MT: '+356 #### ####',
  MY: '+60 ## ### ####',
  LT: '+370 ### ## ###',
  LV: '+371 ## ### ###',
  KG: '+996 ### ### ###',
  KW: '+965 #### ####',
  KZ: '+7 ### ### ####',
  JP: '+81 ## #### ####',
  IT: '+39 ### #######',
  IE: '+353 ### ### ###',
  ID: '+62 ## ### ###',
  HU: '+36 ## ### ####',
  DE: '+49 ## ### ####',
  FR: '+33 # ## ## ## ##',
  FI: '+358 ## ### ## ##',
  EE: '+372 ### ####',
  EG: '+20 ## ### ####',
  DK: '+45 ## ## ## ##',
  CZ: '+420 ### ### ###',
  CY: '+357 ## ### ###',
  HR: '+385 ## ### ###',
  CO: '+57 ### ### ####',
  CN: '+86 ## #### ####',
  CL: '+56 # #### ####',
  BG: '+359 ### ### ###',
  BR: '+55 ## #### #####',
  BE: '+32 ## ### ## ##',
  BY: '+375 ## ### ## ##',
  AZ: '+994 ## ### ## ##',
  AT: '+43 ### ### ####',
  AR: '+54 ## #### ####',
  AM: '+374 ## ### ###',
  UA: '+380 ## ### ####',
  RU: '+7 ### ### ## ##',
};

const PhoneNumberModal: FunctionComponent<PhoneNumberModalProps> = memo(
  ({ id = 0, onClose }: PhoneNumberModalProps) => {
    const { t, i18n } = useTranslation();
    const config = useConfig();
    const getProfile = useProfile();
    const [selectedCountry, setSelectedCountry] = useState<any>(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isPhoneError, setIsPhoneError] = useState<boolean>(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);

    const { data, loading, error } = useQuery(
      gql`
        query {
          getConfig {
            getCountries {
              id
              image
              key
              name
            }
          }
        }
      `,
    );

    const { refetch } = useQuery<any>(GET_PROFILE);

    const [updatePhone] = useMutation(
      gql`
        mutation updatePhoneNumber($phoneNumber: String!, $id: Int!) {
          updatePhoneNumber(phoneNumber: $phoneNumber, id: $id) {
            message
          }
        }
      `,
    );

    const countries = data?.getConfig?.getCountries;

    const formatPhoneNumber = (
      mask: string,
      value: string,
      countryKey: string,
    ): string => {
      return new AsYouType().input(value);
    };

    if (loading) {
      return <Loader />;
    } else if (error) {
      return <>OutItemModal | Error! {error.message}</>;
    }

    const handleCountrySelect = (country: any) => {
      setSelectedCountry(country);
      setIsPhoneError(false);
      setIsBtnDisabled(true);
      setPhoneNumber(phoneMasks[country.key].split(' ')[0]);
    };

    const handlePhoneNumberChange = (
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const input = e.target.value;
      if (selectedCountry) {
        const mask = phoneMasks[selectedCountry.key];
        const formattedValue = formatPhoneNumber(
          mask,
          input,
          selectedCountry.key,
        );
        setPhoneNumber(formattedValue);

        if (isValidPhoneNumber(formattedValue)) {
          setIsBtnDisabled(false);
        } else {
          setIsBtnDisabled(true);
        }
      } else {
        setPhoneNumber(input);
      }
    };

    const handleSavePhone = async () => {
      if (isValidPhoneNumber(phoneNumber)) {
        setIsPhoneError(false);
        await updatePhone({
          variables: {
            id: getProfile.id,
            phoneNumber: phoneNumber,
          },
        });

        await refetch();

        toast(t('Phone number saved!'), { autoClose: 3500 });

        onClose();
      } else {
        setIsPhoneError(true);
      }
    };

    return (
      <>
        <div className="box-modal_close arcticmodal-close" onClick={onClose}>
          <div className="icon material-icons">close</div>
        </div>
        <div className="modal-blue-title">{t('withdraw skin')}</div>
        <div className="modal-blue-title-small">
          {t('To withdraw, please enter your mobile number.')}
        </div>

        <div className="modal-skin-phones">
          <div className="phones-change">
            <img
              src={
                selectedCountry
                  ? selectedCountry.image
                  : countries.find((country) => {
                      if (country.key === getProfile.country)
                        setSelectedCountry(country);
                    })
              }
              alt={
                selectedCountry
                  ? selectedCountry.image
                  : countries.find((country) => {
                      if (country.key === getProfile.country)
                        setSelectedCountry(country);
                    })
              }
              width={24}
              height={24}
            />
            <span
              className="icon material-icons"
              data-icon="arrow_drop_down"
            ></span>
            <div className="phones-change-dropdown">
              {countries.map((country) => {
                if (country.key === 'OTHER' || country.key === 'ALL')
                  return <></>;
                return (
                  <div
                    key={country.id}
                    className="phones-change-dropdown-item"
                    onClick={() => handleCountrySelect(country)}
                  >
                    <img
                      src={country.image}
                      alt={country.name[i18n.language]}
                    />
                    <span>{country.name[i18n.language]}</span>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="phones-change-input">
            <input
              type="text"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              maxLength={
                selectedCountry ? phoneMasks[selectedCountry.key].length : 5
              }
              placeholder={
                selectedCountry ? phoneMasks[selectedCountry.key] : ''
              }
            />
            {isPhoneError && (
              <small className="phones-change-error">
                {t('Invalid phone number format!')}
              </small>
            )}
          </div>
        </div>

        <div className="modal-blue-btn-wrap">
          <button
            disabled={isBtnDisabled}
            className="btn-violet"
            onClick={handleSavePhone}
          >
            {t('Okay')}
          </button>
        </div>
      </>
    );
  },
);

const PhoneNumberModalWrapper: FunctionComponent = () => {
  const modalName = 'PhoneNumberModal';

  const {
    onClose,
    isOpen,
    props: { id },
  } = useModal<{ id: number }>(modalName);

  return (
    <Modal show={isOpen} onClose={onClose} width={406} modalName={modalName}>
      <div className="box-modal modal-blue modal-skin">
        <PhoneNumberModal id={id} onClose={onClose} />
      </div>
    </Modal>
  );
};

export default PhoneNumberModalWrapper;
