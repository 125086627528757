import {
  useRef,
  memo,
  cloneElement,
  FunctionComponent,
  ReactElement,
  JSXElementConstructor,
  useCallback,
} from 'react';
import { useClickAway, useKey } from 'react-use';
import _ from 'lodash';
import { useMountTransition } from '~components/hooks';

import ErrorBoundary from '../../pages/error/ErrorBoundary';

interface ElementTypes {
  children?: ReactElement<any, string | JSXElementConstructor<any>>;
  onClose: VoidFunction;
}

const Element: FunctionComponent<ElementTypes> = memo(
  // eslint-disable-next-line react/prop-types
  ({ children, onClose }) => {
    useKey('Escape', (event: any) => {
      if (_.toLower(event.target.nodeName) !== 'input') {
        event.preventDefault();

        onClose();
      }
    });

    return cloneElement(children);
  },
);

Element.displayName = 'Element';

interface ModalProps extends ElementTypes {
  show: boolean;
  modalName: string;
  fade?: boolean;
  width?: number;
  zIndex?: number;
  scrollable?: boolean;
}

const ModalView: FunctionComponent<ModalProps> = props => {
  const { show, fade = true, modalName } = props;
  const hasTransitionedIn = useMountTransition(show, 1000);

  const ref = useRef();

  const onClose = useCallback(() => {
    if (props?.onClose) {
      props?.onClose();
    }
  }, [props]);

  useClickAway(ref, event => {
    const target = event.target as HTMLElement;

    if (_.includes(target?.className, 'modal-wrap')) {
      onClose();
    }
  });

  const state = {
    backdropStyle: {
      zIndex: props.zIndex || 9999,
    },
    modalStyle: {
      overflow: props.scrollable ? 'auto' : 'unset',
      margin: '0 auto',
      width: props.width || 382,
      marginTop: '10vh',
      marginBottom: '10vh',
      display: 'block',
    },
    duration: {
      hide: 400,
      show: 400,
    },
  };

  const { backdropStyle, modalStyle } = state as any;

  return (
    <ErrorBoundary>
      {(hasTransitionedIn || show) && (
        <div
          className={`backdrop ${hasTransitionedIn && 'in'} ${
            show && 'visible'
          }`}
          style={backdropStyle}
        >
          <div className="modal-wrap">
            <div
              ref={ref}
              className="modal"
              style={fade ? modalStyle : {}}
              data-modal={modalName}
            >
              <Element onClose={onClose}>{props.children}</Element>
            </div>
          </div>
        </div>
      )}
    </ErrorBoundary>
  );
};

export default memo(ModalView);
