import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useGetExternalAccounts } from '../hooks';
import { Loader } from '../Loader';

export const Settings = () => {
  const { t } = useTranslation();
  const { data, loading } = useGetExternalAccounts();

  const social = useMemo(() => {
    return [
      {
        name: 'discord',
        service: 5,
      },
      {
        name: 'twitch',
        service: 4,
      },
      {
        name: 'steam',
        service: 0,
      }, 
      {
        name: 'google',
        service: 3,
      },
      {
        name: 'vk',
        service: 1,
      },
      {
        name: 'tg',
        service: 6,
      },
      // {
      //   name: 'inst',
      //   service: 7,
      // },
      {
        name: 'fb',
        service: 2,
      },
    ].map(({ name, service }) => {
      const getAccount = _.find(data?.getProfile?.getExternalAccounts, [
        'service',
        service,
      ]);

      return (
        <a
          className={`socs-list-item ${name} ${
            getAccount ? 'active' : 'disabled'
          }`}
          href={getAccount ? getAccount?.getUrl : '#'}
          key={service}
          target="__blank"
          rel="noopener noreferrer"
        >
          <div className="icon material-icons" data-icon="&#xe86c;"></div>
        </a>
      );
    });
  }, [data]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="profile-controls">
      <div className="profile-controls-socs">
        <div className="socs-list">{social}</div>
      </div>
      <div className="profile-controls-items">
        <Link className="btn-control" to="/settings">
          <div className="icon material-icons">settings</div>
          <div className="tooltip-block">{t('Settings')}</div>
        </Link>
        <a className="btn-control" href="/auth/logout">
          <div className="icon material-icons">logout</div>
          <div className="tooltip-block">logout</div>
        </a>
      </div>
    </div>
  );
};
