import _ from 'lodash';
import dayjs from 'dayjs';
import { GET_ACTIVE_MODAL } from '~components/hooks';
import { subtractPercent } from '~ui/Price';
import { useTranslation } from 'react-i18next';
import { i18Next } from '~frontend/i18n';

export const Case = {
  price: {
    read(price: any, { readField, cache }) {
      const { getActiveModal } = cache.readQuery({
        query: GET_ACTIVE_MODAL,
      });

      const type = readField('type');
      const caseId = readField('id');
      const categoryId = readField('category');
      const caseDiscount = getActiveModal?.getModal?.conditions?.caseDiscount;

      if (
        _.includes(caseDiscount?.type, type) &&
        _.includes(caseDiscount?.categoryId, categoryId)
      ) {
        if (
          getActiveModal?.props?.caseId &&
          getActiveModal?.props?.caseId !== caseId
        ) {
          return price;
        }

        const discount =
          getActiveModal?.getModal?.conditions?.caseDiscount?.discount;

        return subtractPercent(price, discount);
      }

      return price;
    },
  },

  priceOld: {
    read(price: any, { readField, cache }) {
      const { getActiveModal } = cache.readQuery({
        query: GET_ACTIVE_MODAL,
      });

      const type = readField('type');
      const caseId = readField('id');
      const categoryId = readField('category');
      const caseDiscount = getActiveModal?.getModal?.conditions?.caseDiscount;

      if (
        _.includes(caseDiscount?.type, type) &&
        _.includes(caseDiscount?.categoryId, categoryId)
      ) {
        if (
          getActiveModal?.props?.caseId &&
          getActiveModal?.props?.caseId !== caseId
        ) {
          return price;
        }

        price = price || readField('price');

        if (price) {
          const discount =
            getActiveModal?.getModal?.conditions?.caseDiscount?.discount;

          return (readField('price') * 100) / (100 - discount);
        }
      }

      return price;
    },
  },

  getDateEnd: {
    read(getDateEnd: any, { readField, cache }) {
      const { getActiveModal } = cache.readQuery({
        query: GET_ACTIVE_MODAL,
      });

      const type = readField('type');
      const categoryId = readField('category');
      const caseDiscount = getActiveModal?.getModal?.conditions?.caseDiscount;

      if (
        _.includes(caseDiscount?.type, type) &&
        _.includes(caseDiscount?.categoryId, categoryId)
      ) {
        const timeOut =
          getActiveModal?.getModal?.conditions?.caseDiscount?.timeOut;

        return dayjs(getActiveModal?.createdAt)
          .add(timeOut, 'minutes')
          .format();
      }

      return getDateEnd;
    },
  },
  getImage: {
    read(getImage, { readField }) {
      const type = readField('type');

      if (type === 5 && !readField('isAccess')) {
        return 'https://trustunboxing.com/cscase/5ad53266-bdcf-4ec5-99b5-e026b9a278ab.png';
      }

      return getImage;
    },
  },
  getName: {
    read(getName: string, { readField }) {
      const type = readField('type');

      if (type === 5 && !readField('isAccess')) {
        return i18Next.t('Secret case');
      }

      return getName;
    },
  },
};
