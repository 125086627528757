import { useQuery, gql, useMutation } from '@apollo/client';
import { ExternalAccount, Profile as ProfileType } from '~types';
import { FRAGMENT_EXTERNAL_ACCOUNT_FILEDS } from '../../hooks/useGetExternalAccounts';

export const UPDATE_TRADE_URL = gql`
  mutation UpdateTradeUrl($tradeUrl: String!) {
    updateTradeUrl(tradeUrl: $tradeUrl) {
      id
      tradeUrl
    }
  }
`;

export const GET_PROFILE = gql`
  query getProfile {
    getProfile {
      id
      deposit24Hour
      lastFreeGameDate
      deposit24HourTtl
      userName
      userId
      avatar
      social
      money
      battlePassCoins
      coins
      group
      isPay
      createdAt
      tradeUrl
      accessFreeCase
      inputMoney
      bonusCode
      referralMoney
      country
      language
      isPlayEvent
      createdFormat @client
      email
      phone
      getWheelStep
      joinedToGroup
      joinedToGroupTelegram
      joinedToTwitter
      joinedToFacebook
      getDistributionsPaymentConditions
      getExternalAccounts {
        ...ExternalAccountFields
      }
      getSteam @client
      getVk @client
      getTelegram @client
      gaTestId
      isBanned
    }
  }

  ${FRAGMENT_EXTERNAL_ACCOUNT_FILEDS}
`;

type Profile = ProfileType & {
  createdFormat?: string;
  getSteam?: ExternalAccount;
  getVk?: ExternalAccount;
  getTelegram?: ExternalAccount;
};

interface UserProfileData {
  getProfile: Profile;
}

export const useProfile = () => {
  const { data } = useQuery<UserProfileData>(GET_PROFILE);

  return data?.getProfile;
};

export const useUpdateTradeUrl = (tradeUrl: string) => {
  return useMutation(UPDATE_TRADE_URL, {
    variables: { tradeUrl },
  });
};
