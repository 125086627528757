import { gql } from '@apollo/client';
import round from 'lodash/round';

export const Game = {
  chance: {
    read(item: number) {
      if (!item) {
        return null;
      }

      const chance = round(item, 3);

      return chance > 0.001 ? chance : 0.001;
    },
  },
  getStatus: {
    read(getStatus: string, { cache, readField }) {
      const getProfile = cache.readFragment({
        id: `Profile:${readField('userId')}`,
        fragment: gql`
          fragment getGameProfile on Profile {
            id
            accessFreeCase
          }
        `,
      });

      const getType = readField('type', readField('getCase'));

      if (getProfile && getType === 3 && !getProfile.accessFreeCase) {
        return 'Pending unlock';
      }

      return getStatus;
    },
  },
};
