import { useQuery, gql } from '@apollo/client';
import { UserModal as UserModalType } from '~types';
import { FRAGMENT_PROMO_FILEDS } from '~components/pay/PromoCode';

export const FRAGMENT_ACTIVE_MODAL_FILEDS = gql`
  fragment ActiveModalFields on UserModal {
    id
    getModal {
      id
      props
      conditions
      getPromo {
        ...PromoFields
      }
    }
    props
    createdAt
  }

  ${FRAGMENT_PROMO_FILEDS}
`;

export const GET_ACTIVE_MODAL = gql`
  query getActiveModal {
    getActiveModal {
      ...ActiveModalFields
    }
  }

  ${FRAGMENT_ACTIVE_MODAL_FILEDS}
`;

interface GetActiveModalData {
  getActiveModal: UserModalType;
}

export const useGetActiveModal = () => {
  return useQuery<GetActiveModalData>(GET_ACTIVE_MODAL);
};
