import { useQuery, gql, DocumentNode } from '@apollo/client';
import { Distribution as DistributionTypes } from '~types';
import _ from 'lodash';

const FRAGMENT_DISTRIBUTION_FILEDS = gql`
  fragment DistributionFields on Distribution {
    id
    getDistributionGame {
      id
      isJoin
      usersCount
      endDate
      price
      getItem {
        id
        getName
        color
        getImage
      }
    }
  }
`;

interface Types {
  query: DocumentNode;
  fragments?: {
    getDistributions: DocumentNode;
  };
  subscription: DocumentNode;
}

interface Data {
  getDistributions: DistributionTypes;
}

interface Variables {
  input: {
    id?: number;
    url?: string;
  };
}

export const GET_DISTRIBUTIONS: Types = {
  query: gql`
    query useGetDistributions {
      getDistributions {
        ...DistributionFields
      }
    }

    ${FRAGMENT_DISTRIBUTION_FILEDS}
  `,
  subscription: gql`
    subscription updateEvent {
      updateEvent
    }
  `,
  fragments: {
    getDistributions: FRAGMENT_DISTRIBUTION_FILEDS,
  },
};

export const useGetDistributions = () => {
  return useQuery<Data, Variables>(GET_DISTRIBUTIONS.query, {
    ssr: false,
  });
};
