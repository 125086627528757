import { useQuery, gql } from '@apollo/client';
import { Categories, CategoryInput } from '~types';

import { GET_CASE } from './index';

interface GetCategoriesData {
  getCategories: Categories;
}

export const GET_CASES_CATEGORIES = gql`
  query useGetCategories($input: CategoryInput!) {
    getCategories(input: $input) {
      count
      getCategories {
        id
        isSmall
        getName
        getDescription
        color
        getCases {
          ...CaseFields
        }
      }
    }
  }

  ${GET_CASE.fragments.getCase}
`;

export default (where = {}) => {
  return useQuery<GetCategoriesData, { input: CategoryInput }>(
    GET_CASES_CATEGORIES,
    {
      defaultOptions: {
        variables: {
          input: {
            offset: 0,
            limit: 2,
            where,
          },
        },
      },
    },
  );
};
