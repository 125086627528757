import { useQuery, gql } from '@apollo/client';
import { FRAGMENT_ITEM_FILEDS } from '../../profile/items';
import { Item as ItemType } from '~types';

interface GetItemVar {
  id: number;
}

interface GetItemData {
  getItem: ItemType;
}

interface GetItemsVar {
  ids: number[];
}

interface GetItemsData {
  getItems: ItemType[];
}

export const useGetItem = (id: number) => {
  const { data } = useQuery<GetItemData, GetItemVar>(
    gql`
      query GetItem($id: Int!) {
        getItem {
          ...ItemFields
        }
      }

      ${FRAGMENT_ITEM_FILEDS}
    `,
    {
      variables: { id },
    },
  );

  return data?.getItem;
};

export const useGetItems = (ids: number[]) => {
  const { data } = useQuery<GetItemsData, GetItemsVar>(
    gql`
      query GetItems($ids: [Int!]!) {
        getItems(ids: $ids) {
          ...ItemFields
        }
      }

      ${FRAGMENT_ITEM_FILEDS}
    `,
    {
      variables: { ids },
    },
  );

  return data?.getItems;
};
